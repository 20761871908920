import React from 'react';
import { Link } from 'react-router-dom';
import { Form, FormGroup, Label, Input } from 'reactstrap';
export class Quizsetup extends React.Component {

    render() {
        //const { match, location, history } = this.props
        //const showSearch = this.props.showSearch
        //console.log (showSearch)
        //console.log (showSearch)

        if( this.props.jsonReady && this.props.loggedIn ){
            return (
                <div className="setup-quiz">
                    <div className="header">
                        <h2>あなたのことを教えてください。</h2>
                    </div>
                    <div className="content">
                        <div className="form-wrapper">
                            <Form className="form">
                            <p className="bold">性別</p>
                                <FormGroup check>
                                    
                                    <Label check>
                                        <Input type="radio" 
                                                name="radio1"
                                                value='女性'
                                                checked={this.props.sex === '女性'} 
                                                onChange={this.props.setSex}

                                                 />{' '}
                                        女性　
                                    </Label>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <Label check>
                                        <Input type="radio" 
                                                name="radio1"
                                                value='男性'
                                                checked={this.props.sex === '男性'} 
                                                onChange={this.props.setSex}
                                                />{''}
                                        男性　
                                    </Label>
                                </FormGroup>
                            <br /><br />
                            <p className="bold">生年月日</p>
                                <FormGroup>
                                    <Input  type="select" 
                                            name="year" 
                                            id="year"
                                            value={this.props.dob.year} 
                                            onChange={evt => this.props.setDob(evt, 'year')} 
                                            >
                                    <option>1930年</option>
                                    <option>1931年</option>
                                    <option>1932年</option>
                                    <option>1933年</option>
                                    <option>1934年</option>
                                    <option>1935年</option>
                                    <option>1936年</option>
                                    <option>1937年</option>
                                    <option>1938年</option>
                                    <option>1939年</option>

                                    <option>1940年</option>
                                    <option>1941年</option>
                                    <option>1942年</option>
                                    <option>1943年</option>
                                    <option>1944年</option>
                                    <option>1945年</option>
                                    <option>1946年</option>
                                    <option>1947年</option>
                                    <option>1948年</option>
                                    <option>1949年</option>

                                    <option>1950年</option>
                                    <option>1951年</option>
                                    <option>1952年</option>
                                    <option>1953年</option>
                                    <option>1954年</option>
                                    <option>1955年</option>
                                    <option>1956年</option>
                                    <option>1957年</option>
                                    <option>1958年</option>
                                    <option>1959年</option>

                                    <option>1960年</option>
                                    <option>1961年</option>
                                    <option>1962年</option>
                                    <option>1963年</option>
                                    <option>1964年</option>
                                    <option>1965年</option>
                                    <option>1966年</option>
                                    <option>1967年</option>
                                    <option>1968年</option>
                                    <option>1969年</option>

                                    <option>1970年</option>
                                    <option>1971年</option>
                                    <option>1972年</option>
                                    <option>1973年</option>
                                    <option>1974年</option>
                                    <option>1975年</option>
                                    <option>1976年</option>
                                    <option>1977年</option>
                                    <option>1978年</option>
                                    <option>1979年</option>
                                    
                                    <option>1980年</option>
                                    <option>1981年</option>
                                    <option>1982年</option>
                                    <option>1983年</option>
                                    <option>1984年</option>
                                    <option>1985年</option>
                                    <option>1986年</option>
                                    <option>1987年</option>
                                    <option>1988年</option>
                                    <option>1989年</option>
                                    
                                    <option>1990年</option>
                                    <option>1991年</option>
                                    <option>1992年</option>
                                    <option>1993年</option>
                                    <option>1994年</option>
                                    <option>1995年</option>
                                    <option>1996年</option>
                                    <option>1997年</option>
                                    <option>1998年</option>
                                    <option>1999年</option>
                                    <option>2000年</option>
                                    <option>2001年</option>
                                    <option>2002年</option>
                                    <option>2003年</option>
                                    <option>2004年</option>
                                    <option>2005年</option>

                                    
                                    </Input>
                                </FormGroup>


                                <FormGroup>
                                    <Input 
                                            type="select" 
                                            name="month" 
                                            id="month"
                                            value={this.props.dob.month} 
                                            onChange={evt => this.props.setDob(evt, 'month')} 
                                            >
                                    <option>1月</option>
                                    <option>2月</option>
                                    <option>3月</option>
                                    <option>4月</option>
                                    <option>5月</option>
                                    <option>6月</option>
                                    <option>7月</option>
                                    <option>8月</option>
                                    <option>9月</option>
                                    <option>10月</option>
                                    <option>11月</option>
                                    <option>12月</option>


                                    </Input>
                                </FormGroup>

                                <FormGroup>
                                    <Input type="select" 
                                            name="date" 
                                            id="date"
                                            value={this.props.dob.date} 
                                            onChange={evt => this.props.setDob(evt, 'date')} 
                                            >
                                    <option>1日</option>
                                    <option>2日</option>
                                    <option>3日</option>
                                    <option>4日</option>
                                    <option>5日</option>
                                    <option>6日</option>
                                    <option>7日</option>
                                    <option>8日</option>
                                    <option>9日</option>
                                    <option>10日</option>
                                    <option>11日</option>
                                    <option>12日</option>
                                    <option>13日</option>
                                    <option>14日</option>
                                    <option>15日</option>
                                    <option>16日</option>
                                    <option>17日</option>
                                    <option>18日</option>
                                    <option>19日</option>
                                    <option>20日</option>
                                    <option>21日</option>
                                    <option>22日</option>
                                    <option>23日</option>
                                    <option>24日</option>
                                    <option>25日</option>
                                    <option>26日</option>
                                    <option>27日</option>
                                    <option>28日</option>
                                    <option>29日</option>
                                    <option>30日</option>
                                    <option>31日</option>

                                    </Input>
                                </FormGroup>
                            </Form>
                        </div>
                        <button className="btn btn-primary custom-btn btn-lg" type="button" onClick={(e) => this.props.updateHash('/favorite', e)}>次へ</button>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="thankyou">
                        <div className="header">
                            <Link to='/' onClick={this.props.forceInto}  ><button className="btn btn-primary custom-btn btn-lg">ログインしてください。</button></Link>
                        </div>
                </div>
            )
        }
        
    }
    
    componentDidMount() {
        // console.log( "Quizsetup did mount");
        // // const { slug } = this.props.match.params;
        // // console.log( "slug " + slug );
        // console.log( "this.props " + this.props );
        // console.log(JSON.stringify( this.props,null, 4));
    }

    componentWillUnmount() {
        // console.log( "Quizsetup will unmount");
    }

    componentDidUpdate(){
        // console.log( "Quizsetup did update");
    }

    componentDidCatch(){
        // console.log( "Quizsetup did catch");
    }


    
}