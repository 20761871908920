import React from 'react';
import { Form, FormGroup, Input } from 'reactstrap';
import logo from './logo.svg';
export class Home extends React.Component {


    render() {

      var output = [];
      
      if( this.props.logginAction === '' ){
        
      } else if( this.props.logginAction === 'fail' ){
        output.push(
          <div  key={1} 
                className="alert alert-warning">
               <strong>ログインエラー</strong> <br />ユーザー名かパスワードが無効のようです。
          </div>
        )
      } else {

      }


      var output_btn = [];
      if( this.props.logginAction === '' ){
        output_btn.push(
          <button key={1} className="btn btn-primary custom-btn btn-lg" type="button" onClick={ this.props.loginaction }>ログイン</button>
        )
      } else if( this.props.logginAction === 'accessing' ){
        output_btn.push(
          <button key={1} className="btn btn-primary custom-btn btn-lg disabled" type="button" onClick={ this.props.loginaction }>ログイン</button>
        )
      } else {
        output_btn.push(
          <button key={1} className="btn btn-primary custom-btn btn-lg" type="button" onClick={ this.props.loginaction }>ログイン</button>
        )
      }
      
      return (
          <div className="init-home">
            <div className="home-header">
              <h1><img src={logo} alt="ラトリエ デ パルファム" /></h1>
              <h2>パルファム　ソムリエール　コンサルテーション</h2>
              
            </div>
            <div className="home-content">
              <p><br />以下からログインしてください。<br /></p>
              <br /> 
              <div className="form-wrapper">

                {output}

                <Form className="form">
                    <FormGroup>
                      <Input 
                        type="text" 
                        name="code" 
                        id="code" 
                        placeholder="社員コード"
                        value={this.props.username} 
                        onChange={evt => this.props.changeUsername(evt)}
                         />
                    </FormGroup>
                    <FormGroup>
                      <Input 
                          type="password" 
                          name="password" 
                          id="password" 
                          placeholder="パスワード"
                          value={this.props.password} 
                          onChange={evt => this.props.changePassword(evt)} />
                    </FormGroup>
                    
                </Form>
              </div>
              
              {output_btn}

              
            </div>
          </div>

        )
      
    }

    
    componentDidMount() {
      this.props.updatePercAction(0);
        // console.log( "Home did mount");
    }

    componentWillUnmount() {
        // console.log( "Home will unmount");
    }

    componentDidUpdate(){
        // console.log( "Home did update");
    }

    componentDidCatch(){
        // console.log( "Home did catch");
    }


    
}