import React, { Component } from 'react';
// import Slider from 'react-slick';
import './styles/css/Slick.css';


class QuiztmplColor extends Component {

 
  render() {
    var list = []

    // console.log( "selectedAnswers" )
    // console.log( this.props.selectedAnswers )
    

    if( this.props.loop.length === 0 ){
        this.props.forceIntro()
        return null
    } else {
        var loop = this.props.loop
        list = []
        var myanswerFlag =  (this.props.selectedAnswers !== '' ) ? true : false
        for(var i in loop){
          //console.log( loop[i] )
          
          var basepath = process.env.REACT_APP_IMG_HOST + 'uploads3/' ;
          // var imgname = basepath + loop[i].answerImage
          var imgname = basepath + 'color_space.png'
          // var bgimgname = basepath + loop[i].answerImage
          
          var sectionStyle = {
            // 'backgroundImage':  `url(${bgimgname})`,
            // 'backgroundSize': 'contains'
            'backgroundColor': loop[i].answerImage
          };

          if (myanswerFlag){
            if( this.props.selectedAnswers === loop[i].answerTitle ) {
            }  
          }
          var classname = "color-block color-block-" + loop[i].answerID
          var focusClass = ( this.props.selectedAnswers === loop[i].answerTitle ) ? classname + ' focus ' : classname ;
          // var sendvalue = loop[i].answerID;
          // console.log(focusClass)
          list.push(
                  <div 
                      key={loop[i].answerID} 
                      className={focusClass} 
                      style={sectionStyle} 
                  > 

                        <img src={imgname} alt=""
                            className="img-responsive"
                            key={loop[i].answerID} 
                            data-quiz-id={this.props.pageIndex} 
                            data-answer-id={loop[i].answerID}
                            // onClick={(e) => this.props.answeraction(e)}
                            onTouchStart={(e) => this.props.answeraction(e)}
                            onMouseUp={(e) => this.props.answeraction(e)}

                         />
                       
                  </div>

            )
        }
    }

    if( this.props.pageIndex === this.props.selectedIndex){
          return(
              <div className="color-block-wrap clearfix">
                {list}
              </div>
          )

    } else {
          return(
              <div></div>
          )
    }
  }

  // componentDidMount() {
  //     console.log( "Skrollerc4 did mount");
  // }

  // componentWillUnmount() {
  //     console.log( "Skrollerc4 will unmount");
  // }

  componentDidUpdate(){
      // console.log( "Skrollerc4 did update");
  }

  componentDidCatch(){
      // console.log( "Skrollerc4 did catch");
  }



  componentDidMount(){
      window.addEventListener('touchstart', this.touchStart);
      window.addEventListener('touchmove', this.preventTouch, {passive: false});
  }

  componentWillUnmount(){
      window.removeEventListener('touchstart', this.touchStart);
      window.removeEventListener('touchmove', this.preventTouch, {passive: false});
  }

  touchStart(e){
      this.firstClientX = e.touches[0].clientX;
      this.firstClientY = e.touches[0].clientY;
  }

  preventTouch(e){
      const minValue = 5; // threshold

      this.clientX = e.touches[0].clientX - this.firstClientX;
      this.clientY = e.touches[0].clientY - this.firstClientY;

      // Vertical scrolling does not work when you start swiping horizontally.
      if(Math.abs(this.clientX) > minValue){ 
          e.preventDefault();
          e.returnValue = false;
          return false;
      }
  }
};
export default QuiztmplColor;