import React, { Component } from 'react';
//import { Nav,NavItem,NavDropdown,MenuItem, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
// import logo from './logo_wht.svg';
// import {
//   Collapse,
//   Navbar,
//   //NavbarToggler,
//   //NavbarBrand,
//   Nav,
//   NavItem
  
// } from 'reactstrap';
class Mynavisurveymenumouillette extends Component {
  
  render() {

    var parfumOnSliders = this.props.parfumOnSliders
    var parfumlist = this.props.parfumlist.products
    // console.log ( "bestparfum for menu" )
    // console.log ( bestparfum )
    // console.log ( parfumlist)
    var myblankimage = process.env.REACT_APP_IMG_HOST + 'uploads3/logo.jpg' 
    var additionalclass = this.props.myAnsweredClass + ' center'

    
    var defaultval = 0;
    var defaultid = 0;

      for(var i in parfumOnSliders){
         // console.log( "parfumOnSliders[i].parfumID" )
         // console.log( parfumOnSliders[i].parfumID )
         // console.log( "parfumOnSliders[i].value" )
         // console.log( parfumOnSliders[i].value )
         if( parfumOnSliders[i].value !== undefined ){
           if( defaultval <  parfumOnSliders[i].value ){
             defaultval =  parfumOnSliders[i].value
             defaultid =  parfumOnSliders[i].parfumID
           }
         }
      }
      if( defaultval !== 0 ){
        myblankimage = parfumlist[defaultid].parfumImage
      }
      // console.log( "defaultid")
      // console.log( defaultid)

    return(
      <div className="col-4 col-sm-4 col-md-4" key={this.key} >
       <Link to={this.props.to} onClick={this.props.closeNavbar} 
                       className='nav-link'
                       data-id={this.props.questionID}
                       data-index={this.props.questionIndex} 
                       >
         <div className="inner"><span>{this.props.questionTag}</span>
         <img src={myblankimage} 
               alt={this.props.questionTitle} 
               className={additionalclass}
               />
         </div>
       </Link>
     </div>
    )
  }

  componentDidMount(){

  }
  componentDidMount(){

  }


  // shouldComponentUpdate(){

  // }


  componentDidUpdate(){

  }
}
export default Mynavisurveymenumouillette;
