import React from 'react';
// import ReactDOM from 'react-dom';
import * as ReactDOMClient from 'react-dom/client';
import { HashRouter as Router } from "react-router-dom";
// import { BrowserRouter as Router, Route} from 'react-router-dom';

//import ReactBootstrap from 'react-bootstrap';
//import { Nav,NavItem,NavDropdown,MenuItem, Navbar, Jumbotron, Button } from 'react-bootstrap';
//import Navbar from 'react-bootstrap/lib/Navbar';
import './styles/css/Index.css';
import App from './App';
import history from './History';
//import Tip from './Tip';
//import Mynavi from './Mynavi';
//import LoginButton from './LoginButton';
// import registerServiceWorker from './registerServiceWorker';



const container = document.getElementById('root');
const root = ReactDOMClient.createRoot(container);
root.render(
  
  <Router history={history}>
    <App />
  </Router>
  
);
// ReactDOM.render(<Router history={history}><App /></Router>, document.getElementById('root'));



// registerServiceWorker();
