import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import QuiztmplColor from './QuiztmplColor';
// import QuiztmplSlick from './QuiztmplSlick';
import QuiztmplSelector from './QuiztmplSelector';
import QuiztemplC1parallax from './QuiztemplC1parallax';
import Quizheaders from './Quizheaders';
// import QuiztmplSlider from './QuiztmplSlider';
import {TweenLite,Power4} from "gsap/all";
// import scrollTo from '../node_modules/gsap/ScrollToPlugin';

//import {TweenMax, Power2, TimelineLite} from "gsap";
//import './styles/css/Progressbar.css';
//import './styles/css/BgVideo.css';
class Quizarea extends Component {

  scrollToTop(){
    // TweenLite.to(window, 2.0, {
    //   //scrollTo:window.innerHeight,
    //   scrollTo:0,
    //   ease: Power4.easeOut
    // });

  }



  render() { 
    

    if( this.props.jsonReady && this.props.loggedIn ){

      var myindex = parseInt(this.props.pageIndex, 10) + 1
      var list = this.props.quizlist[this.props.pageIndex]
      var answers = list.answers
      // var output = []
      console.log( "this.props.quizanswer[myindex]" )
      console.log( this.props.quizanswer[myindex] )
      var selected_answer_index = this.props.quizanswer[myindex]
      var selected_answer = ''

      for(var i in answers){
        if( selected_answer_index == answers[i].answerID ){
          selected_answer = answers[i].answerTitle
        }
      }
      console.log( 'selected_answer' )
      console.log( selected_answer )

      // this.props.pageIndex
      var type = list.question.questionType 
      var component = []

      var myclass;
      if (type === 'color') {
          myclass = 'each-quiz color-style'
          component.push(
            <QuiztmplColor {...this.props}
              key={this.props.pageIndex}
              loop={this.props.quizlist[this.props.pageIndex].answers} 
              answeraction={this.props.answeraction} 
              selectedAnswers={selected_answer} 
              pageIndex={myindex} 
              selectedIndex={myindex}
              />
          )
      }
      if (type === 'parallax') {
          myclass = 'each-quiz style1'
          component.push(
            <QuiztemplC1parallax {...this.props}
              key={this.props.pageIndex}
              loop={this.props.quizlist[this.props.pageIndex].answers} 
              answeraction={this.props.answeraction} 
              selectedAnswers={selected_answer} 
              pageIndex={myindex} 
              selectedIndex={myindex}
              />
          )
      }
      if (type === 'select') {
          myclass = 'each-quiz select-style'
          component.push(
            <QuiztmplSelector {...this.props}
              key={this.props.pageIndex}
              loop={this.props.quizlist[this.props.pageIndex].answers} 
              answeraction={this.props.answeraction} 
              selectedAnswers={selected_answer} 
              pageIndex={myindex} 
              selectedIndex={myindex}
              />
          )
      }


      return (
          <div className={myclass} >
            
            <Quizheaders  {...this.props}
                          selectedAnswers={selected_answer}
                          />

            <div className='answers' id='answers'>
              {component}
            </div>
          </div>
           
        )

    } else {
      return (
                <div className="thankyou">
                        <div className="header">
                            <Link to='/' onClick={this.props.forceInto}  ><button className="btn btn-primary custom-btn btn-lg">ログインしてください。</button></Link>
                        </div>
                </div>
            )
    }
  }

  componentDidMount() {
      // console.log( "Quizarea did mount");
    // this.scrollToTop()
  }

  componentWillUnmount() {
      // console.log( "Quizarea will unmount");
  }

  componentDidUpdate(){
      console.log( "Quizarea did update");
      // this.scrollToTop()
  }

  componentDidCatch(){
      // console.log( "Quizarea did catch");
  }



  
};

export default Quizarea;
