import React, { Component } from 'react';
import { Form, FormGroup, Input } from 'reactstrap';
// import Slider from 'react-slick';
import './styles/css/Slick.css';

var myselected 
class QuiztmplSelector extends Component {

  constructor(props) {
    super(props);
    this.state = {
      myselected : 0
    };
  }

  render() {
    var list = []

    // console.log( "Selector init" )
    // console.log( "this.props.selectedAnswers >>> " + this.props.selectedAnswers)

    if( this.props.loop.length === 0 ){
        this.props.forceIntro()
        return null
    } else {
        var selectedID = 0
        var loop = this.props.loop
        var id = 'selector' + this.props.pageIndex
        list = []
        // var myanswerFlag =  (this.props.selectedAnswers !== '' ) ? true : false
        for(var i in loop){
          list.push(
              <option value={loop[i].answerID} 
                      key={loop[i].answerID}>{loop[i].answerTitle}</option>
          )
        }
    }



    if( this.props.pageIndex === this.props.selectedIndex){
          return(
              <div className="select-block-wrap clearfix">
                <div className="form-wrapper">
                      <Form className="form">
                          <FormGroup>
                              <Input  type="select" 
                                      name={id}  
                                      id={id} 
                                      data-quiz-id={this.props.pageIndex} 
                                      value={this.state.myselected} 
                                      // onChange={evt => this.props.answeraction(evt)} 
                                      onChange={evt => this.updateform(evt)} 
                                      >
                                 {list}
                              </Input>
                          </FormGroup>
                      </Form>
                  </div>
              </div>
          )

    } else {
          return(
              <div></div>
          )
    }
  }

  updateform (evt){
      var v = evt.target.value
      console.log('myselected >>> ' + v)

      this.setState({
        myselected: v
      })
      this.props.answeraction(evt)
  }
  // componentDidMount() {
  //     console.log( "Skrollerc4 did mount");
  // }

  // componentWillUnmount() {
  //     console.log( "Skrollerc4 will unmount");
  // }

  componentDidUpdate(){
      // console.log( "Skrollerc4 did update");
  }

  componentDidCatch(){
      // console.log( "Skrollerc4 did catch");
  }



  componentDidMount(){



    if( this.props.loop.length === 0 ){
        this.props.forceIntro()
        return null
    } else {
        var selectedID = 0
        var loop = this.props.loop
        var list = []
        // var myanswerFlag =  (this.props.selectedAnswers !== '' ) ? true : false
        for(var i in loop){
          // console.log( loop[i] )
          if (loop[i].answerTitle === this.props.selectedAnswers)  selectedID = loop[i].answerID          
        }
        console.log ( "selectedID onDID MOUNT. >>> " +  selectedID )
        var v = selectedID;
        
        this.setState({
          myselected: v
        })
        // myselected = this.props.selectedAnswers;
    }

      window.addEventListener('touchstart', this.touchStart);
      window.addEventListener('touchmove', this.preventTouch, {passive: false});
  }

  componentWillUnmount(){
      window.removeEventListener('touchstart', this.touchStart);
      window.removeEventListener('touchmove', this.preventTouch, {passive: false});
  }

  touchStart(e){
      this.firstClientX = e.touches[0].clientX;
      this.firstClientY = e.touches[0].clientY;
  }

  preventTouch(e){
      const minValue = 5; // threshold

      this.clientX = e.touches[0].clientX - this.firstClientX;
      this.clientY = e.touches[0].clientY - this.firstClientY;

      // Vertical scrolling does not work when you start swiping horizontally.
      if(Math.abs(this.clientX) > minValue){ 
          e.preventDefault();
          e.returnValue = false;
          return false;
      }
  }
};
export default QuiztmplSelector;