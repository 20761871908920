import React from 'react';
// import { Form, FormGroup, Input } from 'reactstrap';
import { Link } from 'react-router-dom';
export class Loadingjson extends React.Component {

    render() {
        //const { match, location, history } = this.props
        //const showSearch = this.props.showSearch
        //console.log (showSearch)
        
        var selectors = []

        if( this.props.loggedIn ){

            if( this.props.jsonReady ){
              selectors.push(
                <div key={1}>
                  
                </div>
              )
            } else {
                // this.props.loadAction()
                selectors.push(
                    <div key={2}>
                        <br />
                        <div >読み込み中</div>
                    </div>
                )
            }

            return (
                <div>
                    {selectors}
                </div>
            );
        } else {
            return (
                <div className="thankyou">
                        <div className="header">
                            <Link to='/' onClick={this.props.forceInto}  ><button className="btn btn-primary custom-btn">ログインしてください。</button></Link>
                        </div>
                </div>
            )
        }  
    }
    
    componentDidMount() {
        this.props.updatePercAction(0);
        this.props.loadAction()
        console.log( "Loading did mount");
        console.log( "this.loggedIn");
        console.log( this.props.loggedIn);
    }

    componentWillUnmount() {
        // console.log( "Quizinit will unmount");
        // this.props.updatePercAction(0);
    }

    componentDidUpdate(){
        console.log( "Loading did update");
    }

    componentDidCatch(){
        // console.log( "Quizinit did catch");
    }

}

export default Loadingjson;