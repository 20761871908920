import React, { Component } from 'react';
// import Slider from 'react-slick';
import {TweenLite,Power4} from "gsap/all";
// import scrollTo from '../node_modules/gsap/ScrollToPlugin';
//import './styles/css/Slick.css';



class Quizheaders extends Component {


  render() {
    //var list = []
    // var myindex2 = parseInt(this.props.pageIndex, 10) + 1
    // var list = this.props.quizlist[this.props.pageIndex]
    // var selected_answer_index = this.props.quizanswer[myindex2]

    
    return(
      <div className="quiz-header">
        <h3>{this.props.quizlist[this.props.pageIndex].question.questionTitle }</h3>
        <Answers {...this.props}
                 />
      </div>
    )
  }

  // componentDidMount() {
  //     console.log( "Skrollerc4 did mount");
  // }

  // componentWillUnmount() {
  //     console.log( "Skrollerc4 will unmount");
  // }

  componentDidUpdate(){
      // console.log( "Quizheaders did update");
  }

  componentDidCatch(){
      // console.log( "Quizheaders did catch");
  }




  componentDidMount(){
      window.addEventListener('touchstart', this.touchStart);
      window.addEventListener('touchmove', this.preventTouch, {passive: false});
  }

  componentWillUnmount(){
      window.removeEventListener('touchstart', this.touchStart);
      window.removeEventListener('touchmove', this.preventTouch, {passive: false});
  }

  touchStart(e){
      this.firstClientX = e.touches[0].clientX;
      this.firstClientY = e.touches[0].clientY;
  }


  preventTouch(e){
      const minValue = 5; // threshold

      this.clientX = e.touches[0].clientX - this.firstClientX;
      this.clientY = e.touches[0].clientY - this.firstClientY;

      // Vertical scrolling does not work when you start swiping horizontally.
      if(Math.abs(this.clientX) > minValue){ 
          e.preventDefault();
          e.returnValue = false;
          return false;
      }
  }

  




};


class Answers extends Component{

  scrollToBottom(){
    // TweenLite.to(window, 1.0, {
    //   scrollTo:window.innerHeight,
    //   //scrollTo:'#answers',
    //   ease: Power4.easeOut
    // });
  }

  

  render(){

    Object.size = function(obj) {
        var size = 0, key;
        for (key in obj) {
            if (obj.hasOwnProperty(key)) size++;
        }
        return size;
    };

    // console.log( "this.props.selectedAnswers" )
    // console.log( this.props.selectedAnswers )

    if ( this.props.pageIndex === 8){

        // console.log( "parfumOnSliders" )
        // console.log( this.props.parfumOnSliders )
        // var size = Object.size(this.props.parfumOnSliders);
        
        var selected_parfums = this.props.parfumOnSliders
        var c = 0
        for(const f in selected_parfums){
          
          if( selected_parfums[f].parfumID === undefined ){

          } else {
            c ++
          }

        }
        var size = c

        if( size === 0 ){
          return (
              <div className="selected-answer">
                  <a 
                          onClick={(e) => this.scrollToBottom()}

                  ><p>回答する<span className="icon-down-open-big"></span></p></a>
              </div>
            )

        } else {
             return(
                 <div className="selected-answer">
                  選択中のムエット数 : {size} <br />
                  </div>
              )
        }

        

    } else {

      if( this.props.selectedAnswers.length === 0 ){
        return (
            <div className="selected-answer">
                <a 
                        onClick={(e) => this.scrollToBottom()}

                ><p>回答する<span className="icon-down-open-big"></span></p></a>
            </div>
          )
      } else {
        //console.log( this.props.selectedAnswers.length )
        //console.log( 'this.props.selectedAnswers ')
        return(
          <div className="selected-answer">
              選択中の答え <br />
              {this.props.selectedAnswers}
          </div>

        );
      }

    }


    

    
  }

};


export default Quizheaders;