import React, { Component } from 'react';
import Slider from 'react-slick';
import './styles/css/Slick.css';
// import {Tooltip} from 'react-lightweight-tooltip';

// import {VelocityComponent, VelocityTransitionGroup} from 'velocity-react';
//require('velocity-animate');
//require('velocity-animate/velocity.ui');

class Slick extends Component {



  render() {
    var thumbs = []
    var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false
    };
    thumbs[0] = []
    var selected_brand = this.props.selectedBrand

    
    var cnt = 0
    var index = 0

    for(const b in this.props.parfumlist.products){
      // console.log( selected_brand )
      // console.log( this.props.parfumlist.products[b].relBrandSlug )
      // console.log ( "check")  
 
        if( selected_brand == this.props.parfumlist.products[b].relBrandSlug ){

          

          var myclass = ( this.props.parfumlist.products[b].parfumID == this.props.selectedParfum ) ? 'thum active' : 'thum'
          
          
          
     
          thumbs[index].push(
              <div key={b} className="slick-thum">

                <div className={myclass} >

                  <img src={this.props.parfumlist.products[b].parfumImage} 
                        alt={this.props.parfumlist.products[b].parfumName} 
                        onClick={this.props.onSelectParfum}
                        data-id={this.props.parfumlist.products[b].parfumID}
                        data-name={this.props.parfumlist.products[b].parfumName}
                  />
                  
                  <span> { this.props.parfumlist.products[b].parfumName }</span>
                </div>
              </div>
          )

          cnt++
          // const g = cnt % 8
          // console.log('g')
          // console.log(g)

          if( cnt >= 8 ){
            cnt = 0
            index++
            thumbs[index] = []
            // console.log('index')
            // console.log(index)
          }
        }
    }
    // console.log('cnt')
    // console.log(cnt)

    // const num_slick_container = Math.ceil(cnt / 8)
    // console.log ( 'num')
    // console.log ( num_slick_container )

    // for(var q in thumbs){
      // console.log ( thumbs[q] )
    // }
    // console.log( "thumbs" )
    // console.log( thumbs )
    // console.log( thumbs.length )
    var total = []

    for( var f=0; f<=thumbs.length; f++ ){
      if ( thumbs[f] !== undefined && thumbs[f].length >= 1){
        total.push(
            <div key={f} className="clearfix">
              {thumbs[f]}
            </div>
        )
      }
    }

    window.console.log( "Slick > props => " +  this.props.show );
    return (
      <div className="slick-wrap">
        <Slider {...settings}>
             {total}
        </Slider>
      </div>
    );
  }
}

export default Slick;
