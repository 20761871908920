// func test
    export function add(a, b) {
      return a + b;
    }




// set + get cookies set of functions

    export function setCookie(name,value,days) {
        var expires = "";
        if (days) {
            var date = new Date()
            date.setTime(date.getTime() + (days*24*60*60*1000))
            expires = "; expires=" + date.toUTCString()
        }
        document.cookie = name + "=" + (value || "")  + expires + "; path=/"
    }
    export function getCookie(name) {
        var nameEQ = name + "="
        var ca = document.cookie.split(';')
        for(var i=0;i < ca.length;i++) {
            var c = ca[i]
            while (c.charAt(0)===' ') c = c.substring(1,c.length)
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length)
        }
        return null;
    }
    export function eraseCookie(name) {   
        document.cookie = name+'=; Max-Age=-99999999;' 
    }



// window width & height

    export function getWindowInfo(){
        var rtn = {};
        var w,d,documentElement,body,width,height;
        w = window;
        d = document;
        documentElement = d.documentElement;
        body = d.getElementsByTagName('body')[0];
        width = w.innerWidth || documentElement.clientWidth || body.clientWidth;
        height = w.innerHeight|| documentElement.clientHeight|| body.clientHeight;

        rtn.width = width;
        rtn.height = height;
        return rtn;
    }