import React, { Component } from 'react';
//import { Nav,NavItem,NavDropdown,MenuItem, Navbar } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
// import logo from './logo_wht.svg';
// import {
//   Collapse,
//   Navbar,
//   //NavbarToggler,
//   //NavbarBrand,
//   Nav,
//   NavItem
  
// } from 'reactstrap';
class Mynavisurveymenublank extends Component {
  
  

  render() {
    var myblankimage = process.env.REACT_APP_IMG_HOST + 'uploads3/logo_pattern.png' 


    return(
      <div className="col-4 col-sm-4 col-md-4" key={this.key} >
         <div className="inner">
           <img src={myblankimage} alt="ラトリエ デ パルファム"  />
         </div>
     </div>
    )
  }


  componentDidMount(){

  }
  componentDidMount(){

  }

  // shouldComponentUpdate(){

  // }


  componentDidUpdate(){

  }
}
export default Mynavisurveymenublank;
