import React from 'react';
import { Link } from 'react-router-dom';
import { Form, FormGroup, Input } from 'reactstrap';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import Slick from './Slick';

export class Quizmouillette extends React.Component {


    render() {

        var list = []
        var selected = []
        
        if( this.props.jsonReady && this.props.loggedIn ){
            var fixq = this.props.quizlist[1]
            // console.log( 'fixq' )
            // console.log( fixq )
            var title = fixq.question.questionTitle
            // var loop = fixq.answers
            // var output = []
            


            var loop = this.props.parfumlist.brands
            list = []

            list.push(
                  <option key={-1} value='none'>ブランドの選択</option>

            )
            for(var i in loop){
              //console.log( loop[i] )
              // var classname = "slider-block slider-block-" + loop[i].answerID
              //var imgname = "http://bbjsurvey2018.welovegmix.com/uploads/" + loop[i].answerImage
              // var sendvalue = loop[i].answerID;
             
              
              list.push(
                      <option key={i} value={loop[i].brandSlug}>{loop[i].brandNameJ} </option>

                )
            }

            // var selected_brand = this.props.selectedBrand

            
            // for(const b in this.props.parfumlist.products){
            //   // console.log( selected_brand )
            //   // console.log( this.props.parfumlist.products[b].relBrandSlug )
            //   // console.log ( "check")  
            //     if( selected_brand == this.props.parfumlist.products[b].relBrandSlug ){

            //       var myclass = ( this.props.parfumlist.products[b].parfumID == this.props.selectedParfum ) ? 'thum active' : 'thum'

            //       thumbs.push(
            //           <div className={myclass} key={b} >
            //             <img src={this.props.parfumlist.products[b].parfumImage} 
            //                   alt={this.props.parfumlist.products[b].parfumName} 
            //                   onClick={this.props.onSelectParfum}
            //                   data-id={this.props.parfumlist.products[b].parfumID}
            //                   data-name={this.props.parfumlist.products[b].parfumName}


            //           />
            //           </div>
            //       )
            //     }
            // }

            

            var selected_parfums = this.props.parfumOnSliders
            var parfum_list = this.props.parfumlist.products

            // console.log ("this.props.parfumOnSliders" );
            // console.log (this.props.parfumOnSliders );


            for(const f in selected_parfums){
              

              if( selected_parfums[f].parfumID == undefined ){
                // console.log( 'selected_parfums[f] is empty [f]===>' + f  )

                        selected.push(
                          <p key={f}
                           > <i className="fas fa-plus add-parfum"
                                onClick={this.props.onSelectSlot}
                                data-id={f}
                           ></i>
                          </p>
                        )

              } else {
                // selected.  So add slider
                // console.log( 'selected_parfums[f] not empty [f]===>' + f  )
                      for(var p in parfum_list){
                
                        if ( parfum_list[p].parfumID == selected_parfums[f].parfumID ){
                          //console.log( selected_parfums[f] );
                          const v = selected_parfums[f].value
                          selected.push(
                              <div className="slider-wrap" key={f}>
                                <h4>
                                  <span className="sq-thum">
                                    <img src={parfum_list[p].parfumImage} alt={parfum_list[p].parfumName} />
                                  </span>
                                  {parfum_list[p].parfumName}

                                  <span className="delete-icon">
                                    <i className="fa fa-trash" aria-hidden="true"
                                        onClick={this.props.onDeleteParfum }
                                        data-id={parfum_list[p].parfumID}

                                    ></i>
                                     


                                  </span>
                                </h4>
                                  <Slider 
                                        onChange={ (e) => {this.props.onSliderChange(e,f)} } 
                                        onAfterChange={ (e) => {this.props.onSliderChangeFinished(e,f)}}
                                        min={0} 
                                        max={5}
                                        step={1} 
                                        defaultValue={v}
                                        key={f} 
                                        dots={true}
                                        handleStyle={{
                                          // borderColor: 'blue',
                                          height: 18,
                                          width: 18,
                                          marginLeft: -5,
                                          marginTop: -2,
                                          // backgroundColor: 'black',
                                        }}
                                        trackStyle={
                                          {
                                            marginTop: 2,
                                            height: 8
                                          }
                                        }
                                        railStyle={
                                          {
                                            marginTop: 1,
                                            height: 10
                                          }
                                        }
                                        dotStyle={
                                          {
                                            borderWidth: 8,
                                            bottom: -10,
                                            marginLeft: -5

                                            // borderColor: 'red',
                                          }
                                        }
                                        className={'slider'+f}
                                  />

                              </div>
                          )
                        } 
                      }
              }


              
            }



            return (
                <div className="each-quiz">
                    <div className="quiz-header">
                        <h3>{title}</h3>

                        <br /><br />
                        <button className="btn btn-primary btn-lg custom-btn" type="button" onClick={(e) => this.props.updateHash('/thankyou', e)}>決定する</button>

                    </div>
                    <br /> 
                    <div className="content">
                        

                        <div className="slider-block-wrap" >
                          <div className="header">
                            
                            <div className="form-wrapper">
                              <Form className="form">
                                <FormGroup>
                                  <Input 
                                            type="select" 
                                            name="parfums" 
                                            id="parfums"
                                            value={this.props.selectedBrand} 
                                            onChange={evt => this.props.onSelectBrand(evt)} 
                                            >

                                      {list}
                                    </Input>
                                </FormGroup>
                              </Form>
                            </div>
                            <div className="thumb-wrap">
                              
                               <Slick {...this.props} />
                            </div>

                          </div>

                          <div className="content">
                            <div className="slider-block">
                              {selected}
                            </div>
                          </div>
                      </div>


                    </div>
                </div>
            )
        } else {
            return (
                <div className="thankyou">
                        <div className="header">
                            <Link to='/' onClick={this.props.forceInto}  ><button className="btn btn-primary custom-btn">ログインしてください。</button></Link>
                        </div>
                </div>
            )
        }
    }
    
   componentDidMount(){
      window.addEventListener('touchstart', this.touchStart);
      window.addEventListener('touchmove', this.preventTouch, {passive: false});
  }

  componentWillUnmount(){
      window.removeEventListener('touchstart', this.touchStart);
      window.removeEventListener('touchmove', this.preventTouch, {passive: false});
  }

  touchStart(e){
      this.firstClientX = e.touches[0].clientX;
      this.firstClientY = e.touches[0].clientY;
  }

  preventTouch(e){
      const minValue = 5; // threshold

      this.clientX = e.touches[0].clientX - this.firstClientX;
      this.clientY = e.touches[0].clientY - this.firstClientY;

      // Vertical scrolling does not work when you start swiping horizontally.
      if(Math.abs(this.clientX) > minValue){ 
          e.preventDefault();
          e.returnValue = false;
          return false;
      }
  }
    
};
