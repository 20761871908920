import React, { Component , useRef} from 'react';
import {TweenMax, Power2} from "gsap";
import './styles/css/Progressbar.css';
//import './styles/css/BgVideo.css';
class Pregressbar extends Component {

  
  

  componentDidMount() {
    let pbar = document.getElementById("pbar")
    // let fenetre = useRef(null)
    TweenMax.to(pbar, 0.01, {alpha:1, width: '0%', ease:Power2.easeInOut})
  }

  componentWillUnmount() {

  }



  componentDidUpdate(){
    //console.log( this.props.jsonReady )
  }

  componentDidCatch(){

  }

  render() {

    var _this = this;
    // let fenetre = useRef(null)
    let pbar = document.getElementById("pbar")
    if( !this.props.jsonReady ){
      if( this.props.perc === 0 ) {
        TweenMax.to(pbar, 0.001, {alpha:1, width: '0%', ease:Power2.easeInOut})
      } else {
        TweenMax.to(pbar, 1, {width: this.props.perc + '%', ease:Power2.easeInOut, repeat:0 ,onComplete:function(){
            if( _this.props.jsonReady &&  _this.props.perc >= 100){
              TweenMax.to(pbar, 0.5, {alpha: 0, ease:Power2.easeInOut, repeat:0})
            }
        }})
      }
    }

    //window.console.log( "ByVideo > props.videoURL =>" )
    return (
     <div id="pbar" >
       <div id="bar"></div>
     </div>
    );
  }
};

export default Pregressbar;
