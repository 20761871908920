import React, { Component } from 'react';
import Plx from 'react-plx';

class Quizpager extends Component {

  render() { 

    const exampleParallaxData = [
        
        {
          start: 300,
          duration: 50,
          properties: [
           
            
            {
              startValue:1.0,
              endValue: 0,
              property: "opacity"
            }
          ]
        }
      ]



     // console.log( "this.props.history.location.pathname" )
     // console.log( this.props.history.location.pathname )
     // console.log( "scenes" )
     const scenes = this.props.scenes
     var myindex = scenes.indexOf(this.props.history.location.pathname)
     // console.log( 'this.props.history.location.pathname' )
     // console.log( this.props.history.location.pathname )
     // console.log( myindex )

     // console.log( "scenes" )
     // console.log( scenes )
     // console.log( "myindex" )
     // console.log( myindex )
     
  
        if( myindex === -1 ){
           return(
          
              <div className="side-arrow-navs">
                
              </div>
              
            )
        } else if( myindex === 80 ){
           return(
          
              <div className="side-arrow-navs">
                
              </div>
              
            )
        } else{
         return(
            
            <div className="side-arrow-navs">
              <Plx
                  key={1} 
                  className='MyAwesomeParallax'
                  parallaxData={ exampleParallaxData }
              >
                  <div className="iconbox icon-left-open-big" onClick={(e) => this.props.prevScene(e)}></div>
                  <div className="iconbox icon-right-open-big" onClick={(e) => this.props.nextScene(e)}></div>
              </Plx>
            </div>
            
          )
        }

    
  }

  componentDidMount() {
      // console.log( "Quizpager did mount");
  }

  componentWillUnmount() {
      // console.log( "Quizpager will unmount");
  }

  componentDidUpdate(){
      // console.log( "Quizpager did update");
  }

  componentDidCatch(){
      // console.log( "Quizpager did catch");
  }

 
};

export default Quizpager;