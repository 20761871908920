import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import {TweenLite,Power4} from "gsap/all";
import axios from 'axios';
import { Table } from 'reactstrap';
import Pagination from "react-js-pagination";
import logo from './logo.svg';

var myreports = []
// var activePage = 1
var itemperpage = 10

class Surveylist extends Component {

  constructor(props) {
    super(props);
    this.state = {
      activePage: 1
    };
  }

  handlePageChange = (pageNumber) => {
    console.log(`active page is ${pageNumber}`);
    this.setState({activePage: pageNumber});
    
  }

  render() { 
    

    if( this.props.jsonReady && this.props.loggedIn && myreports.length >= 1 ){

      var list = []
      var total = myreports.length
      var current = this.state.activePage

      var start = (current - 1) * itemperpage
      var end = start + itemperpage
      
// console.log( 'total => ' + total )
// console.log( 'itemperpage => ' + itemperpage )
// console.log( 'current => ' + current )
// console.log( 'start => ' + start )
// console.log( 'end => ' + end )
      
      for( var i in myreports ){

        if( i >= start && i < end ){

            var to = '/survey/edit/' + myreports[i].surveyID

            // var foundCustomerCode = ( myreports[i].customerCode != '' ) ? true : false;
            var explode = myreports[i].publishDate.split(' ');
            list.push(
                <tr key={i}>
                  <td><span className="date">{explode[0]} <br /> {explode[1]}</span></td>
                  <td><span className="sex">{myreports[i].sex}</span></td>
                  <td><span className="code">{ myreports[i].customerCode}</span></td>
                  <td><Link to={to} >編集</Link></td>
                  <td></td>
                </tr>
            )
        }
      }

      return (
          <div className="report-init"> 
            <div className="header">
                <h1><img src={logo} alt="ラトリエ デ パルファム" /></h1>
                <h3>過去14日間に実施したコンサルテーション一覧</h3>
            </div>
            <div className="content">
                <br />  
                <div className="surveylist-wrap">
                    <div className="container">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="surveylist">
                            <div className="table-responsive">
                              <Table striped >
                                <thead>
                                  <tr>
                                    <th className="th-date">
                                      日付
                                    </th>
                                    <th className="th-sex">
                                      性別
                                    </th>
                                    <th className="th-code">
                                      顧客コード
                                    </th>
                                    <th className="th-button">
                                      編集
                                    </th>
                                    <th className="th-long"></th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {list}
                                </tbody>
                                
                              </Table>
                            </div>

                            <div className="pagination-wrap">
                              <Pagination
                                activePage={this.state.activePage}
                                itemsCountPerPage={itemperpage}
                                totalItemsCount={total}
                                pageRangeDisplayed={5}
                                onChange={this.handlePageChange}
                                innerClass='pagination justify-content-center' 
                                itemClass='page-item'
                                linkClass='page-link'
                              />
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>

            
          </div>
           
      )  

    } else {


      return (
            <div className="thankyou">
              <div className="header">
                  <Link to='/' onClick={this.props.forceInto}  ><button className="btn btn-primary custom-btn btn-lg">ログインしてください。</button></Link>
              </div>
            </div>
      )
    }
  }

  componentDidMount() {
    var p = this.props
    console.log( 'survey list mounted')
    

    var _api_entry_point = process.env.REACT_APP_HOST + 'api/myreport.json'  ;
    axios.get(_api_entry_point , {
      params: { 
        ID: Math.floor((Math.random() * 100000)),
        staffID: this.props.staffID
      }
    })
    .then(function(response){

      console.log( response )
      if (response.data.result === true ){
        myreports = response.data.data
        p.onUpdate(myreports)
      }
    })
    .catch(function (error) { 
      console.log(error); 
    });

  }

  componentWillUnmount() {
    console.log( 'survey list will be removed')
  }

  componentDidUpdate(){
  }

  componentDidCatch(){
  }

  
};

export default Surveylist;
