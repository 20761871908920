import React, { Component } from 'react';
//import { Nav,NavItem,NavDropdown,MenuItem, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
// import logo from './logo_wht.svg';
// import {
//   Collapse,
//   Navbar,
//   //NavbarToggler,
//   //NavbarBrand,
//   Nav,
//   NavItem
  
// } from 'reactstrap';
class Mynavisurveymenu extends Component {
  
  render() {

    if( this.props.questionType === 'color' ){
      return(
        <div className="col-4 col-sm-4 col-md-4" key={this.key} >
          <Link to={this.props.to} 
               onClick={this.props.closeNavbar} 
               className='nav-link'
               data-id={this.props.questionID}
               data-index={this.props.questionIndex} 
               >
            <div style={this.props.style}
                className="inner"

            ><span>{this.props.questionTag}</span>
              <img src={this.props.myimage} 
                 alt={this.props.questionTitle} 
                 className={this.props.myAnsweredClass}
                 />
            </div>
          </Link>
       </div>
      )

    } else {
      return(
        <div className="col-4 col-sm-4 col-md-4" key={this.key} >
          <Link to={this.props.to} 
               onClick={this.props.closeNavbar} 
               className='nav-link'
               data-id={this.props.questionID}
               data-index={this.props.questionIndex} 
               >
            <div className="inner"><span>{this.props.questionTag}</span>
              <img src={this.props.myimage} 
                 alt={this.props.questionTitle} 
                 className={this.props.myAnsweredClass}
                 />
            </div>
          </Link>
       </div>
      )

    }


    
  }

  componentDidMount(){

  }
  componentDidMount(){

  }


  // shouldComponentUpdate(){

  // }

 

  componentDidUpdate(){

  }
}
export default Mynavisurveymenu;
