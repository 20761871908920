import React from 'react';
import { Link } from 'react-router-dom';
import logo from './logo.svg';
export class Thankyou extends React.Component {
    // componentWillReceiveProps(nextProps, nextContext) {
    //     console.log( "Report will receive props");
    // }
    render() {


        // console.log ( this.props.loggedIn )
        // console.log ( this.props.dob )
        // console.log ( this.props.sex )
        // console.log ( this.props.staffID )

        // console.log ( this.props.quizlist )
        // console.log ( this.props.quizanswer )

        // console.log ( this.props.parfumOnSliders )

        if( this.props.loggedIn === true ){

            var mybtn = []

            

            if( this.props.dataSent === 0 ){
                
                if( this.props.sendAction === 'fail'){
                    mybtn.push(
                        <button key={1} onClick={this.props.registerAction} className="btn btn-primary  btn-lg custom-btn" type="button" >データの登録をする</button>
                    )

                } else if( this.props.sendAction === 'accessing'){
                    mybtn.push(
                        <button key={1} onClick={this.props.registerAction} className="btn btn-primary  btn-lg custom-btn disabled" type="button" >データの登録をする</button>
                    )

                } else {
                    mybtn.push(
                        <button key={1} onClick={this.props.registerAction} className="btn btn-primary  btn-lg custom-btn" type="button" >データの登録をする</button>
                    )
                }
            } else {
                if( this.props.sendAction === 'success'){
                    mybtn.push(
                       <p key={1}> 無事データが送信できました。</p>
                    )
                } 
                
                

            }

            return (
                <div className="thankyou">

                    <div className="header">
                        <h1><img src={logo} alt="ラトリエ デ パルファム" /></h1>
                        <h2>すべての回答が終わりました。<br />ありがとうございました。</h2>
                        <br />
                       

                        {mybtn}

                    </div>
                </div>
            );
        } else {



            return (
                <div className="thankyou">
                        <div className="header">
                            <Link to='/' onClick={this.props.forceInto}  ><button className="btn btn-primary custom-btn">ログインしてください。</button></Link>
                        </div>
                </div>
            )
        }
        
    }
}

