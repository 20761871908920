import React, { Component } from 'react';


//import { ParallaxProvider, Parallax } from 'react-skrollr'
// import { Parallax } from 'react-scroll-parallax';
//import './styles/css/Skrollparallax.css';
import Plx from 'react-plx';
// import {PolarGrid,RadarChart,PolarAngleAxis,PolarRadiusAxis,Radar,ComposedChart, Area, BarChart, Bar, RadialBarChart,RadialBar, ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, PieChart, Pie, Sector, Cell } from 'recharts';
// import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';

class QuiztemplC1parallax extends Component {

  render() {
    var list = []
   

    if( this.props.loop.length === 0 ){
        // this.props.forceIntro()
        return null
    } else {
        var loop = this.props.loop
        list = []
        var myanswerFlag =  (this.props.selectedAnswers !== '' ) ? true : false
        


        var myindex = parseInt(this.props.pageIndex,10 ) - 1
        var sexflag = parseInt(this.props.quizlist[myindex].question.questionSexflag,10)
        // console.log( 'flag' )  
        // console.log( sexflag )  
        // console.log ( this.props.quizlist[myindex].question )
        var mysexString = this.props.sex;
        var mysexInt = ( mysexString === '女性' )? 0 : 1
        


        for(var i in loop){
          var classname = "col-12 image-block-sq image-block-sq-smaller image-block-" + loop[i].answerID
          var imgname = loop[i].answerImage
       
          const exampleParallaxData = [
              {
                start: 'self',
                duration: 400 ,
                properties: [
                 
                  {
                    startValue: 50,
                    endValue: 0,
                    property: "translateY"
                  }
                ]
              }
            ]
          // var sendvalue = loop[i].answerID;
          if (myanswerFlag){
            if( this.props.selectedAnswers === loop[i].answerTitle ) {
            }  
          }
          var focusClass = ( this.props.selectedAnswers === loop[i].answerTitle ) ? 'inner focus ' : 'inner';
        
          if( sexflag === 1 ){

              if( parseInt(loop[i].answerSex,10) === mysexInt ){

                list.push(
                      <div className={classname}
                            key={loop[i].answerID}  
                      >
                        <Plx
                            key={loop[i].answerID} 
                            className='MyAwesomeParallax'
                            parallaxData={ exampleParallaxData }
                        >
          
                          <div className={focusClass} 
                                key={loop[i].answerID} 
                                // data-quiz-id={this.props.pageIndex} 
                                // data-answer-id={loop[i].answerID}
                                // onClick={(e) => this.props.answeraction(e)}
                                
                              >
                              <div className="overlay" 
                                  key={loop[i].answerID} 
                                >
                                  <img src={imgname} alt="" 
                                      className="img-stretch"
                                      data-quiz-id={this.props.pageIndex} 
                                      data-answer-id={loop[i].answerID}
                                      onTouchStart={(e) => this.props.answeraction(e)}
                                      onMouseUp={(e) => this.props.answeraction(e)}
                                  />
                              </div>
                          </div>
                        </Plx>
                      </div>
                )

            }

          } else {


            list.push(
                    <div className={classname}
                          key={loop[i].answerID}  
                    >
                      <Plx
                          key={loop[i].answerID} 
                          className='MyAwesomeParallax'
                          parallaxData={ exampleParallaxData }
                      >
        
                        <div className={focusClass} 
                              key={loop[i].answerID} 
                              // data-quiz-id={this.props.pageIndex} 
                              // data-answer-id={loop[i].answerID}
                              // onClick={(e) => this.props.answeraction(e)}
                              
                            >
                            <div className="overlay" 
                                key={loop[i].answerID} 
                              >
                                <img src={imgname} alt="" 
                                    className="img-stretch"
                                    data-quiz-id={this.props.pageIndex} 
                                    data-answer-id={loop[i].answerID}
                                    onTouchStart={(e) => this.props.answeraction(e)}
                                    onMouseUp={(e) => this.props.answeraction(e)}
                                />
                            </div>
                        </div>
                      </Plx>
                    </div>
              )
          }
        }
    }

    if( this.props.pageIndex === this.props.selectedIndex){
          return(
              <div className="parallx-block-wrap">
                <div className="row">{list}</div>
              </div>
          )

    } else {
          return(
              <div></div>
          )
    }
  }

   componentDidMount(callback) {
      // console.log( "Quizarea did mount");
      // console.log( "QuiztemplC1parallax did mount");
  }

  componentWillUnmount(callback) {
      // console.log( "Quizarea will unmount");
      // console.log( "QuiztemplC1parallax will unmount");
  }

  componentDidUpdate(){
      // console.log( "QuiztemplC1parallax did update");
  }

  componentDidCatch(){
      // console.log( "QuiztemplC1parallax did catch");
  }



};
export default QuiztemplC1parallax;