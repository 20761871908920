import React, { Component } from 'react';
import Sound from 'react-sound';

class Soundtrigger2 extends Component {

  render() { 

      var ss
      if( this.props.hoverplay === 1 && this.props.soundevent === 1 ) {
        ss = Sound.status.PLAYING

      } else {
         
        ss = Sound.status.STOPPED
      
      }


      return( 

            <div className="sound-trigger">
                      
              <Sound
                  url="audio/hit2.mp3"
                  playStatus={ss}
                  // playStatus={Sound.status.PLAYING}
                  playFromPosition={0 /* in milliseconds */}
                  autoLoad={true}
                  volume={40}
                  // onLoading={this.handleSongLoading}
                  // onPlaying={this.handleSongPlaying}
                  // onFinishedPlaying={this.handleSongFinishedPlaying}
                  onFinishedPlaying={this.props.onSoundFinishedPlaying}
                />
            </div>
      )   
     

    
  }

  componentDidMount() {
      // console.log( "Quizpager did mount");
  }

  componentWillUnmount() {
      // console.log( "Quizpager will unmount");
  }

  componentDidUpdate(){
      // console.log( "Quizpager did update");
  }

  componentDidCatch(){
      // console.log( "Quizpager did catch");
  }


  
};

export default Soundtrigger2;
