import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import {TweenLite,Power4} from "gsap/TweenMax";
// import axios from 'axios';
import { Table } from 'reactstrap';
// import Pagination from "react-js-pagination";
import { Form, FormGroup, Input } from 'reactstrap';
import logo from './logo.svg';



class Surveyedit extends Component {

  constructor(props) {
    super(props);
    this.state = {
      mytext: '',
      mysurveyid: 0
    };
  }
  handlePageChange = (pageNumber) => {
    console.log(`active page is ${pageNumber}`);
    this.setState({activePage: pageNumber});
  }

  updatetextinput = (evt) => {
    // console.log('i params >>> ' + evt.target.value )
    var tempcode = evt.target.value
    this.setState({
      mytext: tempcode
    })
  }
  render() { 

    var _id = 0
    var _pubdate = ''
    var _code = undefined
    var _sex = ''
    var _inputvalue= undefined

    if( this.props.jsonReady && this.props.loggedIn && this.props.surveyList.length >= 1){

      for( var i in this.props.surveyList ){
          if( this.props.myid === parseInt(this.props.surveyList[i].surveyID) ){
            // console.log ( this.props.surveyList[i] )
            _id = this.props.surveyList[i].surveyID
            _pubdate = this.props.surveyList[i].publishDate
            _code = this.props.surveyList[i].customerCode
            _sex = this.props.surveyList[i].sex
          }
      }

      return (
            <div className="report-init">
              <div className="header">
                <h1><img src={logo} alt="ラトリエ デ パルファム" /></h1>
                <h3><small>{_pubdate} に実施</small><br/>コンサルテーション詳細</h3>
              </div>
              <div className="content">
                <div className="surveylist-wrap">
                  <div className="container">
                    <div className="row">
                      <div className="col-sm-12">
                        <Table>
                          <tbody>
                            <tr>
                              <td>実施日</td>
                              <td>{_pubdate}</td>
                            </tr>
                            <tr>
                              <td>性別</td>
                              <td>{_sex}</td>
                            </tr>
                            <tr>
                              <td>コード</td>
                              <td>

                                <Form className="form">
                                  <FormGroup>
                                    <Input 
                                      type="text" 
                                      name="customercode" 
                                      id="code" 
                                      placeholder="顧客コード"
                                      value={this.state.mytext} 
                                      onChange={this.updatetextinput}
                                       />
                                      
                                  </FormGroup>
                              </Form>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                        <Link to='/surveylist'  className='' >
                          <button className="btn btn-primary custom-btn " type="button">戻る</button>
                        </Link> &nbsp;
                        <button className="btn btn-primary custom-btn " type="button" onClick={ this.props.updateCustomerCode } data-id={this.state.mysurveyid} data-code={this.state.mytext} >アップデート</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      )
    } else {
      return (
            <div className="thankyou">
              <div className="header">
                  <Link to='/' onClick={this.props.forceInto}  ><button className="btn btn-primary custom-btn btn-lg">ログインしてください。</button></Link>
              </div>
            </div>
      )

    }
  }

  componentDidMount() {
    // console.log( 'survey edit mounted')
    // console.log( 'this.props.myid' )
    // console.log( this.props.myid )

    var _id = 0
    // var _pubdate = ''
    var _code = undefined
    // var _sex = ''
    var _inputvalue= undefined
    if( this.props.jsonReady && this.props.loggedIn && this.props.surveyList.length >= 1){
      for( var i in this.props.surveyList ){
          if( this.props.myid === parseInt(this.props.surveyList[i].surveyID) ){
            // console.log ( this.props.surveyList[i] )

              _id = this.props.surveyList[i].surveyID
              // _pubdate = this.props.surveyList[i].publishDate
              _code = this.props.surveyList[i].customerCode
              // _sex = this.props.surveyList[i].sex

              _inputvalue = ( _code !== undefined ) ? _code : ''
             

            this.setState({
              mytext: _inputvalue,
              mysurveyid: _id
            });   
          }
      }
    }

    

    

  }

  componentWillUnmount() {
    console.log( 'survey edit will be removed')
  }

  componentDidUpdate(){
  }

  componentDidCatch(){
  }



  
};

export default Surveyedit;
