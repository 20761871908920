import React, { Component } from 'react';
//import { Nav,NavItem,NavDropdown,MenuItem, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from './logo_wht.svg';
import Mynavisurveymenu from './Mynavisurveymenu';
import Mynavisurveymenumouillette from './Mynavisurveymenumouillette';
import Mynavisurveymenublank from './Mynavisurveymenublank';
import {
  Collapse,
  Navbar,
  //NavbarToggler,
  //NavbarBrand,
  Nav,
  NavItem
  //NavLink,
  // UncontrolledDropdown,
  // DropdownToggle,
  // DropdownMenu,
  // DropdownItem 
} from 'reactstrap';
class Mynavi extends Component {

  
  render() {
    // const msg = this.props.flag ? "ログアウトテスト" : "ログインテスト";
    // const nav2 = this.props.page === '2' ? "active" : "";
    // const nav3_1 = this.props.page === '3.1' ? "active" : "";
    // const nav3_2 = this.props.page === '3.2' ? "active" : "";
    // const nav3_3 = this.props.page === '3.3' ? "active" : "";
    // const nav3_4 = this.props.page === '3.4' ? "active" : "";
    // const nav4 = this.props.page === '4' ? "active" : "";
    // const nav5 = this.props.page === '5' ? "active" : "";
    //window.console.log ( "Mynavi > props => " +  this.props.page  )

    // const login_buttons =  (this.props.loggedIn ) ? 'ログアウト' : 'ログイン'



    if( this.props.jsonReady && this.props.loggedIn ){

            var shortmode = this.props.shortquizmode
            
            var loop = this.props.quizlist
            // var output1 = []
            // var output2 = []
            // var output3 = []

            var newoutput1 = []
            // var newoutput2 = []
            // var newoutput3 = []

            var shortout1 = []
            // var shortout2 = []
            // var shortout3 = []
            
            var cnt= 0
            var limit = 4
            
            for(var i in loop){

                var innerloop
                var style = {}
                var mycolor
                var myAnsweredClass = (  this.props.quizanswer[parseInt(i,10)+1] !== undefined  ) ? 'answered' : '' ;
                var myimage
                var myanswerkey
                var quiztype = this.props.quizlist[i].question.questionType

                var tolink = '/q' + loop[i].question.questionIndex

                // console.log ( "this.props.quizlist[i].question" )
                // console.log ( this.props.quizlist[i].question )

                if( shortmode ){

                // short mode

                 if( this.props.quizlist[i].question.questionShortFlag == "1" && cnt < limit ){
                   cnt ++ ;
                   // console.log( 'short => ' + cnt ) 
                   // console.log ( this.props.quizlist[i] )

                    if (  this.props.quizanswer[parseInt(i,10)+1] !== undefined  ) {
                      myanswerkey = this.props.quizanswer[parseInt(i,10)+1];
                      innerloop = this.props.quizlist[i].answers
                      for(var f in innerloop ){
                          if( innerloop[f].answerID == myanswerkey ){
                            myimage = innerloop[f].answerImage
                          }
                      }

                      if( quiztype == 'color' && myAnsweredClass == 'answered') {
                          myAnsweredClass = 'color-answered'
                          innerloop = this.props.quizlist[i].answers
                          for(var g in innerloop ){
                            // console.log( "g" )
                            // console.log( g )
                            // console.log( "innerloop[g].answerID" )
                            // console.log( innerloop[g].answerID )
                            // console.log( "innerloop[g].answerID" )
                            // console.log( innerloop[g].answerID )
                            

                            if( innerloop[g].answerID == myanswerkey ){
                              mycolor = innerloop[g].answerImage
                              // console.log ( "mycolor" )
                              // console.log ( mycolor )
                            }
                          } 
                          style = {
                              backgroundColor: mycolor
                          } 
                      }  
                    } else {
                      myimage = loop[i].question.questionImage;  
                    }

                    if( quiztype == 'color'){
                      myimage = loop[i].question.questionImage;  
                    }

                    shortout1.push(
                       <Mynavisurveymenu
                         key={cnt} 
                         to={tolink}
                         closeNavbar={this.props.closeNavbar}
                         questionID={loop[i].question.questionID}
                         questionIndex={loop[i].question.questionIndex}
                         questionType={loop[i].question.questionType}
                         myimage={myimage} 
                         questionTag={loop[i].question.questionTag} 
                         questionTitle={loop[i].question.questionTitle} 
                         myAnsweredClass={myAnsweredClass} 
                         style={style}
                       >
                       </Mynavisurveymenu>
                    )

                    if ( cnt == 1 ){
                      shortout1.push(
                        <Mynavisurveymenublank
                         key={ cnt * 10} 
                       >
                       </Mynavisurveymenublank>
                      )
                    }


                    if ( cnt == 2 ){
                      shortout1.push(
                        <Mynavisurveymenublank
                         key={ cnt * 10 + 1} 
                       >
                       </Mynavisurveymenublank>
                      )
                      
                      
                      shortout1.push(
                       <Mynavisurveymenumouillette {...this.props}
                         key={cnt * 100 } 
                         to="/mouillette"
                         closeNavbar={this.props.closeNavbar}
                         questionID={101}
                         questionIndex={0}
                         // myimage={myimage} 
                         questionTitle={'title'} 
                         myAnsweredClass={myAnsweredClass} 
                         parfumOnSliders={this.props.parfumOnSliders}
                         parfumlist={this.props.parfumlist} 
                       >
                       </Mynavisurveymenumouillette>
                      )
                      
                      shortout1.push(
                        <Mynavisurveymenublank
                         key={ cnt * 10 + 2} 
                       >
                       </Mynavisurveymenublank>
                      )
                    }

                    if ( cnt == 3 ){
                      shortout1.push(
                        <Mynavisurveymenublank
                         key={ cnt * 10 + 4} 
                       >
                       </Mynavisurveymenublank>
                      )
                    }
                 }

                } else {

                  limit = 8
                  if( cnt < limit ){
                    cnt ++ ;
                    // console.log( 'normal => ' + cnt ) 
                    if (  this.props.quizanswer[parseInt(i,10)+1] !== undefined  ) {
                      myanswerkey = this.props.quizanswer[parseInt(i,10)+1];
                      var innerloop = this.props.quizlist[i].answers
                      for(var f in innerloop ){
                          if( innerloop[f].answerID == myanswerkey ){
                            myimage = innerloop[f].answerImage
                          }
                      } 

                      if( quiztype == 'color' && myAnsweredClass == 'answered') {
                          myAnsweredClass = 'color-answered'
                          innerloop = this.props.quizlist[i].answers
                          for(var g in innerloop ){
                            if( innerloop[g].answerID == myanswerkey ){
                              mycolor = innerloop[g].answerImage
                            }
                          } 
                          style = {
                              backgroundColor: mycolor
                          } 
                      }   
                    } else {
                      myimage = loop[i].question.questionImage;  
                    }

                    if( quiztype == 'color'){
                      myimage = loop[i].question.questionImage;  
                    }

                    newoutput1.push(
                       <Mynavisurveymenu
                         
                         key={cnt} 
                         to={tolink}
                         closeNavbar={this.props.closeNavbar}
                         questionID={loop[i].question.questionID}
                         questionIndex={loop[i].question.questionIndex}
                         questionType={loop[i].question.questionType}
                         myimage={myimage} 
                         questionTag={loop[i].question.questionTag} 
                         questionTitle={loop[i].question.questionTitle} 
                         myAnsweredClass={myAnsweredClass} 
                         style={style}
                       >
                       </Mynavisurveymenu>
                    )

                    if ( cnt == 4 ){
                       newoutput1.push(
                         <Mynavisurveymenumouillette {...this.props}
                           key={cnt * 100 } 
                           to="/mouillette"
                           closeNavbar={this.props.closeNavbar}
                           questionID={101}
                           questionIndex={0}
                           // myimage={myimage} 
                           questionTitle={'title'} 
                           myAnsweredClass={myAnsweredClass} 
                           bestparfum={this.props.bestparfum}
                           parfumlist={this.props.parfumlist} 
                           
                         >
                         </Mynavisurveymenumouillette>
                        )
                    }
                  }
                }
            }
    }
    var menuvisibleClass;
    if( this.props.loggedIn  ){
        menuvisibleClass = 'open-menu'
    } else {
        menuvisibleClass = 'close-menu'
    }

    var collapsedClass = (this.props.isOpen) ? 'navbar-toggler' : 'navbar-toggler collapsed';

    var allout = [  newoutput1 ]
    if( shortmode ) allout = [  shortout1 ]
    // var allout = [ output1, output2, output3]




    return (

      <div>
         <Navbar color=""  fixed="top" expand="never">
          
          <button type="button" className={collapsedClass} onClick={this.props.toggle}>
            <span> </span>
            <span> </span>
            <span> </span>
          </button>
          <Collapse isOpen={this.props.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              
              <div className="nav-wrap">
                <NavItem  >
                  <div className="nav-margin-top">
                      <div className={menuvisibleClass}>
                        <div className="btn-wrap">
                          
                          <Link to='/' onClick={this.props.resetall} ><i className="fas fa-sign-in-alt"></i></Link>
                          <Link to='/setup' onClick={this.props.closeNavbar} className='' ><i className="fas fa-user"></i></Link>
                          <Link to='/thankyou' onClick={this.props.closeNavbar} className='' ><i className="fas fa-file-export"></i></Link>
                          <Link to='/surveylist' onClick={this.props.closeNavbar} className='' ><i className="far fa-address-book"></i></Link>
                          
                        </div>
                      </div>
                      <div className="logo">
                        <img src={logo} alt="ラトリエ デ パルファム" />
                      </div>
                  </div>
                </NavItem>
               
                <NavItem className={menuvisibleClass}>
                  <div className="nav-suvery-group" >
                       <div className="row">
                         {allout}
                       </div>
                  </div>
                </NavItem>
                

                <NavItem >
                   <div className="nav-margin-mid">
                    
                  </div>
                </NavItem>
                
                <NavItem >
                  
                  <div className="nav-margin-bottom">
                    
                  </div>
                </NavItem>
              </div>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }

  componentDidMount(){

  }
  componentDidMount(){

  }

  // shouldComponentUpdate(){

  // }

  componentDidUpdate(){

  }

}
export default Mynavi;