import React from 'react';
import { Link } from 'react-router-dom';
import { Form, FormGroup, Input } from 'reactstrap';
export class Quizfavorite extends React.Component {


    render() {
        
        if( this.props.jsonReady && this.props.loggedIn ){
            var fixq = this.props.quizlist[0]
            // console.log( 'fixq' )
            // console.log( fixq )
            var title = fixq.question.questionTitle
            var loop = fixq.answers
            var output = []
            for(var i in loop){
                output.push(
                    <option key={i} value={loop[i].answerTitle}>{loop[i].answerTitle}</option>
                )
            }
            return (
                <div className="setup-quiz">
                    <div className="header more-space">
                        <h2>{title}</h2>
                    </div>
                    <br /> 
                    <div className="content">
                        <div className="form-wrapper">
                            <Form className="form">
                                <FormGroup>
                                    <Input  type="select" 
                                            name="purchased" 
                                            id="purchased" 
                                            value={this.props.purchased} 
                                            onChange={evt => this.props.setPurchased(evt)} 
                                            >
                                       {output}
                                    </Input>
                                </FormGroup>
                            </Form>
                        </div>
                        <button className="btn btn-primary custom-btn btn-lg" type="button" onClick={ (e) => this.props.updateHash('/q1', e) }>次へ</button>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="thankyou">
                        <div className="header">
                            <Link to='/' onClick={this.props.forceInto}  ><button className="btn btn-primary custom-btn btn-lg">ログインしてください。</button></Link>
                        </div>
                </div>
            )
        }
    }
    
    componentDidMount() {
        // console.log( "Quizfavorite did mount");
        // const { slug } = this.props.match.params;
        // console.log( "slug " + slug );
        // console.log( "this.props " + this.props );
        // console.log(JSON.stringify( this.props,null, 4));
    }

    componentWillUnmount() {
        // console.log( "Quizfavorite will unmount");
    }

    componentDidUpdate(){
        // console.log( "Quizfavorite did update");
    }

    componentDidCatch(){
        // console.log( "Quizfavorite did catch");
    }


    
}