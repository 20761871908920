import React from 'react';
import { Link } from 'react-router-dom';
// import { Form, FormGroup, Input } from 'reactstrap';
export class Quizinit extends React.Component {

    render() {
        //const { match, location, history } = this.props
        //const showSearch = this.props.showSearch
        //console.log (showSearch)
        
        if( this.props.jsonReady && this.props.loggedIn ){
            
            // var checkboxflag = (this.props.shortquizmode ) ? true : false
            var loop = this.props.parfumlist.brands
            var output = []
            for(var i in loop){
                //console.log( loop[i] )
                output.push(
                   <div className="eachbrand" key={i}>
                     <div className="inner">
                         <img src={loop[i].brandImage} alt={loop[i].brandName} />
                     </div>
                   </div>
                )
            }


            return (
                <div className="init-quiz">
                    <div className="header">
                        <h3>新たな魅力を引き出す香り選びを、<br />スタートしましょう。</h3>
                       
                       
                        <div className="switch-wrap">
                            <span className="switch">
                                <label htmlFor="switch-id">F　</label>
                                <input type="checkbox" className="switch" 
                                        id="switch-id" 
                                        checked={this.props.shortquizmode} 
                                        onChange={this.props.setMode } />
                                <label htmlFor="switch-id"> S</label>
                            </span>
                        </div>
                        <br />
                        <button className="btn btn-primary btn-lg custom-btn" type="button" onClick={(e) => this.props.updateHash('/setup', e)}>開始</button>
                        

                        
                    </div>
                    <div className="content">
                        <br />  
                        <div className="home-brands clearfix">
                            {output}
                        </div>
                    </div>
                    
                </div>
            );
        } else {
            return (
                <div className="thankyou">
                        <div className="header">
                            <Link to='/' onClick={this.props.forceInto}  ><button className="btn btn-primary custom-btn">ログインしてください。</button></Link>
                        </div>
                </div>
            )
        }  
    }
    
    componentDidMount() {
        // console.log( "Quizinit did mount");
    }

    componentWillUnmount() {
        // console.log( "Quizinit will unmount");
    }

    componentDidUpdate(){
        // console.log( "Quizinit did update");
    }

    componentDidCatch(){
        // console.log( "Quizinit did catch");
    }

   
    
}