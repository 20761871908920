import React, { Component } from 'react';
import { Route, withRouter, Switch} from 'react-router-dom'
import Mynavi from './Mynavi';
import Loadingjson from './Loadingjson';
import Progressbar from './Progressbar';
import Quizarea from './Quizarea';
import Surveylist from './Surveylist';
import Surveyedit from './Surveyedit';
import { Home } from './Home.js';
import { Quizsetup } from './Quizsetup.js';
import { Quizfavorite } from './Quizfavorite.js';
import { Quizmouillette } from './Quizmouillette.js';
import { Quizinit } from './Quizinit.js';
//import { Reportinit } from './Reportinit.js';
import { Thankyou } from './Thankyou.js';
import {TweenMax, TweenLite} from "gsap/all";
import axios from 'axios';
import Quizpager from './Quizpager';
import Soundtrigger from './Soundtrigger';
import Soundtrigger2 from './Soundtrigger2';
import { ParallaxProvider } from 'react-scroll-parallax';
import { TransitionGroup, Transition } from "react-transition-group";
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/css/App.css';

//import { HashLink } from 'react-router-hashlink';
  
import {getWindowInfo } from './Commonfunction.js';
// import { add, setCookie, getCookie, eraseCookie, getWindowInfo } from './Commonfunction.js';

// global base variables, not in state
var scenes = [
  '/q1','/q2','/q3','/q4','/q5',
  '/q6','/q7','/q8', '/mouillette'
]
var shortscenes = [
 
]
// var shortsurveylength = 4;
// var surveylength = 8;
// var tempcode = ''



// var myanswers = {};

class App extends Component {

  constructor(props) {
      super(props);
      this.state = {
        jsonpreload : false,
        loggedIn : false,
        isNavOpen : false,
        logginAction: '',
        sendAction: '',

        show : true,
        pageindex : 0,

        path: '/',
        
        progress_perc : 0,
        window_width: 0,
        window_height: 0,

        shortquizmode : true,
        quizindex : 1,
        quizlist: {},
        fixquizlist: {},
        quizanswer: {},
        parfumlist: {},
        scene: scenes,
        
        selectedparfum: 0, selectedbrand: 0, slidertarget: 1,
        parfumOnSliders: {
          1 : {}, 2 : {}, 3 : {}, 4 : {}, 5 : {},
        },
        // bestparfum: '',
        purchased: '',
        dob: { 'year':'1930年', 'month':'1月', 'date':'1日'},
        sex: '女性',

        username: '',
        password: '',


        staffID: 0,
        surveyList: {},
        selectedCustomerCode: '',
        // ccodeinput: '',
        dataSent: 0,
        hoverplay1: 0,
        soundevent1: 0,
        hoverplay2: 0,
        soundevent2: 0
      };
      //window.console.log( props )
  }

        resetall = (e) => {
          var _this = this
          this.setState({
              progress_perc: 0,
              jsonpreload : false
          })
          var _api_entry_point = process.env.REACT_APP_HOST + 'api/authout.json' ;
          this.serverRequest = axios.post( _api_entry_point)
          //axios.get("http://dev.gmix.jp/img/jobs.json")
          .then(function (response) {
              console.log( response.data );
              if (response.data.loggedout === true ){
                //console.log( 'loggedin');
                _this.setState({
                  // loggedIn: false,
                  // staffID: 0,
                  jsonpreload : false,
                  loggedIn : false,
                  isNavOpen: false,
                  staffID: 0,
                  dataSent: 0,
                  selectedparfum: "1", selectedbrand: "1", slidertarget: 1,
                  parfumOnSliders: {
                  1 : {}, 2 : {}, 3 : {}, 4 : {}, 5 : {},
                  },
                  quizlist: {},
                  fixquizlist: {},
                  quizanswer: {},
                  parfumlist: {},
                  scene: scenes,
                  purchased: '',
                  dob: { 'year':'1930年', 'month':'1月', 'date':'1日'},
                  sex: '女性',

                });
                // _this.props.history.push('/loading')  
              }



          })
          .catch(function (error) {
            console.log(error);
          });
          
        }

        // intializing survey by loading JSONs in Sequence
        loadQuiz = (e) => {
          var _this = this;
          var a,b; 
          var c = 0;
          var _api_entry_point = process.env.REACT_APP_HOST + 'api/surveydata.json' ;
          axios.get(_api_entry_point , {
            params: { ID: Math.floor((Math.random() * 100000)) }
          })
          .then(function(response){
              // console.log ( response )

              if( _this.state.loggedIn === true &&  _this.state.jsonpreload === false ){
                console.log ( response )
                _this.update_progress_perc(100);

                for(var i in response.data.survey){
                   a = response.data.survey[i].question.questionShortFlag 
                   b = response.data.survey[i].question.questionIndex 
                   // console.log ( a )
                   // console.log ( b )
                   if( a == "1" && c < 4 ) {
                     c++
                     shortscenes.push( '/q' + b)
                   }
                }
                shortscenes.push('/mouillette')

                if( _this.state.shortquizmode ) {
                   _this.setState({
                     scene : shortscenes
                   }) 
                }

                _this.setState({ 
                  quizlist: response.data.survey,
                  fixquizlist: response.data.fixedsurvey,
                  jsonpreload: true 
                });
                
                _this.props.history.push('/start')    
              }
              
              // console.log( process.env.REACT_APP_SECRET_CODE )
          })
          .catch(function (error) { 
            console.log(error); 
          });
        }

        loadParfum = (e) => {
          var _this = this;
          var _api_entry_point = process.env.REACT_APP_HOST + 'api/parfum.json' ;
          axios.get(_api_entry_point , {
            params: { ID: Math.floor((Math.random() * 100000)) }
          })
          .then(function(response){

              if( ! _this.state.jsonpreload ){
                _this.setState({ parfumlist: response.data });
                _this.update_progress_perc(50);
                _this.loadQuiz();
              }
          })
          .catch(function (error) { 
            console.log(error); 
          });
        }



  // listener event
  
  updateDimensions = (e) => {
    var w = getWindowInfo();
    this.setState({ window_width : w.width});
    this.setState({ window_height : w.height});
  }
  componentDidMount() {
    // this.loadParfum();
    this.update_progress_perc(0);
    window.addEventListener("resize", this.updateDimensions);
  }
  componentDidMount(){
    this.updateDimensions();
  }
  componentWillUnmount (){
    window.removeEventListener("resize", this.updateDimensions);
  }


// states managers

        // unused : prototype用テストアクション
        toggleLogin = (event) => {
          var result = this.state.loggedIn ? false : true;
          this.setState({ loggedIn: result });
        }

  // for bootstrap navi toggle 
  toggleNavi = (event) => {
    var result = this.state.isNavOpen ? false : true;
    this.setState({ isNavOpen: result });
    window.console.log ( "show");
  }
  // 
  closeNavbar = (event) => {
      this.setState({
          isNavOpen: false
      });
  }



  // progress percentage
  update_progress_perc = (p) => {
    this.setState({progress_perc: p })
  }

 

  // login form input
  onChange_username = (evt) =>{
      this.setState({
        'username': evt.target.value
      })
  }
  onChange_password = (evt) =>{
     this.setState({
        'password': evt.target.value
      })
  }

  // login
  clickOnLogin  = (event) => {

    var disabled = ( event.target.classList.contains('disabled') ) ? true : false;

    var _this = this;

    
    //console.log ( 'Test');
    if( !disabled ) {
        _this.setState({
           logginAction: 'accessing'
        });
        let params = new URLSearchParams();
        params.append('email', this.state.username);
        params.append('password', this.state.password);
        // params.append('email', '0123457');
        // params.append('password', '11111111');
        // params.append('email', 'info@gmix.jp');
        // params.append('password', 'tokitoki');
        params.append('remember_me', '0');

        var _api_entry_point = process.env.REACT_APP_HOST + 'api/auth.json' ;
        this.serverRequest = axios.post( _api_entry_point, params)
        //axios.get("http://dev.gmix.jp/img/jobs.json")
        .then(function (response) {
            console.log( response.data );
            if (response.data.login === true ){
              //console.log( 'loggedin');
              _this.setState({
                 loggedIn: true,
                 staffID: response.data.staffID,
                 logginAction: 'success'
              });
              _this.props.history.push('/loading')  
            } else {
               _this.setState({
                 loggedIn: false,
                 staffID: undefined,
                 logginAction: 'fail'
              });
            }
        })
        .catch(function (error) {
          console.log(error);
        });

    }
  }



  // router : hash
  updateHash = (p, event) => {
    console.log("updateHash")
    this.props.history.push(p)  
  }

  // router : quiz pager
  nextScene = (event) => {
    var scene = this.state.scene
    var length = this.state.scene.length
    var myindex = this.state.scene.indexOf(this.props.history.location.pathname)
    var ret = ( myindex + 1 < length  ) ? myindex + 1 : 0;
    
    if ( ret === 0 ) {
      this.props.history.push('/thankyou')  
    } else {
      this.props.history.push(scene[ret])  
    }
  }

  // router : quiz pager
  prevScene = (event) => {
    var scene = this.state.scene
    var length = this.state.scene.length
    var myindex = this.state.scene.indexOf(this.props.history.location.pathname)
    var ret = ( myindex - 1 >= 0 ) ? myindex - 1 : length - 1;
    this.props.history.push(scene[ret])  
  }

  // router : force login
  forceIntro = () => {
    console.log( 'startover')
    this.props.history.push('/')  
  }



  // set answer for quiz quiz
  setAnswer = (event) => {
    // event.preventDefault()
    console.log( "value => " + event.target.value )
    console.log( "data-quiz-id => " + event.target.getAttribute('data-quiz-id') )
    console.log( "data-answer-id => " + event.target.getAttribute('data-answer-id') )

    var tempstate = this.state.quizanswer
    var newname =  event.target.getAttribute('data-quiz-id')
    if( event.target.getAttribute('data-answer-id') === null ){
      // selector

      if( tempstate[newname] !== event.target.value ){
        tempstate[newname] = event.target.value
        this.setState({
          hoverplay1 : 1,
          soundevent1: 1,
          quizanswer: tempstate
        })
      }
    } else {
      // other
      // console.log( 'other ')
      if( tempstate[newname] !== event.target.getAttribute('data-answer-id') ){
        tempstate[newname] = event.target.getAttribute('data-answer-id')
        this.setState({
          hoverplay1 : 1,
          soundevent1: 1,
          quizanswer: tempstate
        })

        console.log( 'this.state.quizanswer ')
        console.log( this.state.quizanswer )
      }
      
      
    }
    // console.log( 'quizlist' )
    // console.log( this.state.quizlist )

    



    
  }

  // set purchased purchased 
  setPurchased = (evt) => {
    //event.preventDefault()
    var p = evt.target.value
    this.setState({ purchased: p })
    // console.log(this.state.purchased );
  }



  // set date of birth from Form input
  setDob = (evt, segment) => {
    //event.preventDefault()
    var tempstate = this.state.dob
    var p = evt.target.value
    
    if( segment === 'year' ){
      tempstate.year = p;
    } else if( segment === 'month' ){
      tempstate.month = p;
    } else if( segment === 'date' ){
      tempstate.date = p;
    }
    this.setState({ dob: tempstate })

    // console.log( 'DOB >>>>>' );
    // console.log(this.state.dob );
  }


  // quiz mode from Form input
  setMode = (evt) => {
    var p = evt.target.checked
    // console.log ( p )
    var myscene = ( p ) ?  shortscenes : scenes
    this.setState({ 
      shortquizmode: p,
      scene : myscene
    })
    // console.log ( myscene )
  }

  // set sex from Form input
  setSex = (evt) => {
    //event.preventDefault()
    var p = evt.target.value
    this.setState({ sex: p })
    // console.log( '>>>>>' );
    // console.log(this.state.sex );
  }

  // mouillette : select brand 
  onSelectBrand = (evt) => {
    var p = evt.target.value
    this.setState({ selectedbrand: p })
    // console.log( 'onSelectBrand >>>>>' );
    // console.log(this.state.selectedbrand );
  }

  // mouillette : select parfum 
  onSelectParfum = (evt) => {
    var p = evt.currentTarget.dataset.id
    this.setState({
      hoverplay1 : 1,
      soundevent1: 1,
      selectedparfum: p
    })
    // console.log( 'onSelectParfum >>>>>' );
    // console.log(this.state.selectedparfum );
  }
  


  // mouillette : slide slider 
  onSliderChange = (event,f) => {

    var parfumOnSliders = this.state.parfumOnSliders
    if( parfumOnSliders[f].value !== event ){
      parfumOnSliders[f].value = event
      this.setState({
        hoverplay1 : 1,
        soundevent1: 1,
        parfumOnSliders: parfumOnSliders,
      })
      // console.log( this.state.parfumOnSliders );
    }  
  }

  // mouillette : finish slide slider 
  onSliderChangeFinished = (event,f) => {
    
    var parfumOnSliders = this.state.parfumOnSliders
    if( parfumOnSliders[f].value !== event ){
  
      parfumOnSliders[f].value = event
      this.setState({
        hoverplay1 : 1,
        soundevent1: 1,
        parfumOnSliders: parfumOnSliders,
        // bestparfum : defaultid
      })
    }

  }

  
  // mouillette : set slider target
  setSliderTaget = (event) => {
    var t = event.target.getAttribute('data-id')
    var parfumOnSliders = this.state.parfumOnSliders

    var foundSameID = false

    for(var q in parfumOnSliders){
      var _id = parfumOnSliders[q].parfumID
      if( this.state.selectedparfum === _id ) {
         foundSameID = true
      }
    }


    if( !foundSameID && this.state.selectedparfum !== 0 ) {
      parfumOnSliders[t] = {
        'parfumID' : this.state.selectedparfum,
        'value' : 0
      }
      this.setState({ 
        slidertarget : t,
        parfumOnSliders: parfumOnSliders//,
      })
    }
  }

  // mouillette : set parfum on slider target
  setParfumOnSlider = (event) => {
    var target = this.state.slidertarget
    // console.log( 'target' )
    // console.log( target )
    var parfumOnSliders = this.state.parfumOnSliders
    parfumOnSliders[target] = {
      'parfumID' : this.state.selectedparfum,
      'value' : 0
    }
    this.setState({ 
      parfumOnSliders: parfumOnSliders//,
     })
  }

  // mouillette : remove parfum on slider target
  onDeleteParfum = (event) => {

    var p = event.currentTarget.dataset.id
    var parfumloop = this.state.parfumOnSliders
    for(var q in parfumloop){
      var _id = parfumloop[q].parfumID
      if( p === _id ) {
          parfumloop[q] = {}
      }
    }
    this.setState({
      parfumOnSliders: parfumloop
    })
    // console.log( "deleted ")
    // console.log (this.state.parfumOnSliders)
  }


  onDebug = (event) => {
    console.log (this.state.parfumOnSliders)
  }

  onPlaySound1 = (event) => {
    this.setState({
      hoverplay1 : 1,
      soundevent1: 1
    })
    // console.log (this.state.hoverplay1)
  }
  onSoundFinishedPlaying1 = (event) =>{
    this.setState({
      hoverplay1 : 0,
      soundevent1: 0
    })
  }

  onPlaySound2 = (event) => {
    this.setState({
      hoverplay2 : 1,
      soundevent2: 1
    })
    // console.log (this.state.hoverplay1)
  }
  onSoundFinishedPlaying2 = (event) =>{
    this.setState({
      hoverplay2 : 0,
      soundevent2: 0
    })
  }


  // send survey data to API
  registerSurveyData  = (event) => {

    
    var _this = this;

    _this.setState({
      sendAction: 'accessing'
    })
    var disabled = ( event.target.classList.contains('disabled') ) ? true : false;
    //console.log ( 'Test');
    var mydobY = this.state.dob.year.substring(0, this.state.dob.year.length - 1) ;
    var mydobM = this.state.dob.month.substring(0, this.state.dob.month.length - 1) ;
    var mydobD = this.state.dob.date.substring(0, this.state.dob.date.length - 1) ;
    var mydob = mydobY + '-' + mydobM + '-' + mydobD;

    let params = new URLSearchParams();
    params.append('staffID', this.state.staffID );
    params.append('sex', this.state.sex);
    params.append('email', '');
    params.append('dob', mydob );
    params.append('purchasedHistory', this.state.purchased );


    var answerloop = this.state.quizanswer
    // console.log( this.state.quizlist )
    var quizloop = this.state.quizlist
    for(var q in quizloop){

      var qID = quizloop[q].question.questionID
      var qValue = (answerloop[parseInt(q,10) + 1] === undefined ) ? '' : answerloop[parseInt(q,10) + 1]
      var paramname = 'answer' + (parseInt(qID,10))
      console.log ('paramname >>> ' + paramname )
      params.append(paramname, qValue );
    }

    var muettried = this.state.parfumOnSliders
    var str = ''
    var mycount = 0
    for(var t in muettried){
      var myid = muettried[t].parfumID
      var howmuchLiked = muettried[t].value
      if ( t === 0 ){
        // str =  myid + ':' + howmuchLiked
      } else {

        if( howmuchLiked !== undefined){

          if ( str !== '' ) {
            str = str + ';' + myid + ':' +  howmuchLiked 
          } else {
            str = myid + ':' +  howmuchLiked 
          }
        }
      }
    }
    // console.log( str )

    params.append('muetData', str );
    params.append('muetQty', mycount );
    // console.log( params )
    var _api_entry_point = process.env.REACT_APP_HOST + 'api/adddata.json' ;
    if( !disabled ) { 
      this.serverRequest = axios.post( _api_entry_point, params)
      //axios.get("http://dev.gmix.jp/img/jobs.json")
      .then(function (response) {
          console.log( response );
          if (response.data.result === true ){
            _this.setState({
               dataSent: 1,
               sendAction: 'success'
            });
          } else {
            _this.setState({
              sendAction: 'fail'
            })
          }
      })
      .catch(function (error) {
        console.log(error);
      });
    }
  }



  updateSurveyList = (param) => {
    this.setState({
      surveyList: param
    })
  }
  // updateCustomerCodeforminput = (evt) => {
  //   // console.log('i params >>> ' + evt.target.value )
  //   tempcode = evt.target.value
  //   this.setState({
  //     ccodeinput: tempcode
  //   })
  // }

  updateCustomerCode = (evt) => {
    var _this = this
    var _id =  evt.currentTarget.dataset.id
    var _code =  evt.currentTarget.dataset.code
    // console.log( "_evt >>> " + _id)
    console.log( "_id >>> " + _id)
    console.log( "_code >>> " + _code)
    var _api_entry_point = process.env.REACT_APP_HOST + 'api/updatecode.json' ;

    let params = new URLSearchParams();
    params.append('surveyID', _id );
    params.append('customerCode', _code );


    console.log( params )

    this.serverRequest = axios.post( _api_entry_point, params)
    //axios.get("http://dev.gmix.jp/img/jobs.json")
    .then(function (response) {
        console.log( response );
        if (response.data.result === true ){
          // _this.setState({
          //    dataSent: 1
          // });
          // console.log( 'add data GOOOOD');

          _this.props.history.push('/surveylist')  
          
        } else {
          // console.log( 'add data fail');
        }
    })
    .catch(function (error) {
      console.log(error);
    });
  }



  render() {

    const { location } = this.props
    const currentkey = location.pathname.split("/")[1] || ""

    const completeCall = target => {
      TweenLite.set(target, { clearProps: "position, width" });
    };


    const Surveywrap = props => {
      // const { path } = props.match
      const { id } = props.match.params
      var adjustedIndex = parseInt(id);
      
      if (adjustedIndex <= 0 ) adjustedIndex = 0;
      if (typeof id === 'undefined' || id === 0)  {
        return (
          <div>
            <p>slug with id '{id}' does not exist.</p>
          </div>
        )
      }
      return (
            <Surveyedit 
              {...props} 
              jsonReady={this.state.jsonpreload} 
              loggedIn={this.state.loggedIn}
              surveyList={this.state.surveyList}
              updateCustomerCode={this.updateCustomerCode }
              // updateCustomerCodeforminput={this.updateCustomerCodeforminput }
              slug={adjustedIndex}
              myid={adjustedIndex}
              // ccodeinput={this.state.ccodeinput}

              />
      )
    }



    return (
      <div className="App">
        <header>
          <Progressbar perc={this.state.progress_perc} 
                       jsonReady={this.state.jsonpreload} 
           />
                
        </header>

        <Mynavi toggle={this.toggleNavi} 
                    isOpen={this.state.isNavOpen} 
                    closeNavbar={this.closeNavbar} 
                    quizlist={this.state.quizlist} 
                    quizanswer={this.state.quizanswer} 
                    jsonReady={this.state.jsonpreload} 
                    loggedIn={this.state.loggedIn}
                    parfumOnSliders={this.state.parfumOnSliders}
                    // bestparfum={this.state.bestparfum}
                    resetall={this.resetall}
                    parfumlist={this.state.parfumlist} 
                    shortquizmode={this.state.shortquizmode}
                     /> 
                    
       

        <ParallaxProvider>
            <div >
              <TransitionGroup>
                <Transition
                  transitionName="test1"
                  key={currentkey}
                  timeout={500}
                  mountOnEnter={true}
                  unmountOnExit={true}
                  onEnter={node => {
                    // first kill all tweens of the target
                    TweenMax.killTweensOf(node);
                    const parent = node.parentNode;
                    const targetWidth =
                      parent.clientWidth -
                      parseFloat(getComputedStyle(node.parentNode).paddingLeft) * 2;
                    // set the position and properties of the entering element
                    TweenLite.set(node, {
                      position: "fixed",
                      y: -30,
                      autoAlpha: 0,
                      width: targetWidth
                    });
                    // animate in the element
                    TweenLite.to(node, 0.5, {
                      autoAlpha: 1,
                      y: 0,
                      onComplete: completeCall,
                      onCompleteParams: [node]
                    });
                    this.setState({
                        hoverplay2 : 1,
                        soundevent2: 1,
                    })
                  }} // on enter end

                  onExit={node => {
                    // first kill all tweens of the target
                    TweenMax.killTweensOf(node);
                    const parent = node.parentNode;
                    const targetWidth =
                      parent.clientWidth -
                      parseFloat(getComputedStyle(node.parentNode).paddingLeft) * 2;
                    // set the position of the element
                    TweenLite.set(node, {
                      position: "fixed",
                      width: targetWidth
                    });
                    // animate out the element
                    TweenLite.to(node, 0.5, {
                      position: "fixed",
                      opacity: 0
                      //y: -200
                    });
                  }} // on exit end
                >
                <Switch location={location}>
                  <Route exact path='/' render={()=> <Home loginaction={this.clickOnLogin}
                                                            updatePercAction={this.update_progress_perc} 
                                                            username={this.state.username} 
                                                            password={this.state.password} 
                                                            changeUsername={this.onChange_username}
                                                            changePassword={this.onChange_password}
                                                            logginAction={this.state.logginAction}
                                                            
                                                               />}  />
                  <Route path='/loading' render={()=> <Loadingjson updateHash={this.updateHash}
                                                              updatePercAction={this.update_progress_perc} 
                                                              loadAction={this.loadParfum}
                                                              jsonReady={this.state.jsonpreload} 
                                                              parfumlist={this.state.parfumlist} 
                                                              loggedIn={this.state.loggedIn} 
                                                              forceIntro={this.forceIntro} 
                                                              />}  />
                  <Route path='/start' render={()=> <Quizinit updateHash={this.updateHash}
                                                              jsonReady={this.state.jsonpreload} 
                                                              parfumlist={this.state.parfumlist} 
                                                              loggedIn={this.state.loggedIn} 
                                                              forceIntro={this.forceIntro} 
                                                              setMode={this.setMode}
                                                              shortquizmode={this.state.shortquizmode} 
                                                              // shortquizmode={this.props.shortquizmode} 
                                                              />}  />
                  <Route path='/setup/' render={()=> <Quizsetup {...this.props}
                                                                updateHash={this.updateHash} 
                                                                dob={this.state.dob}  
                                                                sex={this.state.sex}
                                                                setDob={this.setDob}   
                                                                setSex={this.setSex}
                                                                loggedIn={this.state.loggedIn} 
                                                                jsonReady={this.state.jsonpreload}  
                                                                forceIntro={this.forceIntro} 
                                                                />}  />
                  <Route path='/favorite/' render={()=> <Quizfavorite {...this.props}
                                                                updateHash={this.updateHash}  
                                                                setPurchased={this.setPurchased}
                                                                jsonReady={this.state.jsonpreload}
                                                                parfumlist={this.state.parfumlist}
                                                                purchased={this.state.purchased} 
                                                                loggedIn={this.state.loggedIn}
                                                                forceIntro={this.forceIntro} 
                                                                quizlist={this.state.fixquizlist} 
                                                                quizanswer={this.state.quizanswer}  
                                                                  />}  />

                  <Route path='/mouillette' render={()=> <Quizmouillette {...this.props} 
                                                      updateHash={this.updateHash}  
                                                      pageIndex={8} 
                                                      sex={this.state.sex}
                                                      answeraction={this.setAnswer}
                                                      quizlist={this.state.fixquizlist} 
                                                      quizanswer={this.state.quizanswer} 
                                                      loggedIn={this.state.loggedIn} 
                                                      jsonReady={this.state.jsonpreload} 
                                                      onSliderChange={this.onSliderChange} 
                                                      onSliderChangeFinished = {this.onSliderChangeFinished} 
                                                      onSelectBrand = {this.onSelectBrand} 
                                                      onSelectParfum = {this.onSelectParfum} 
                                                      onDeleteParfum = {this.onDeleteParfum} 
                                                      onSelectSlot={this.setSliderTaget}
                                                      onDebug = {this.onDebug}
                                                      selectedParfum={this.state.selectedparfum}
                                                      selectedBrand={this.state.selectedbrand}
                                                      parfumlist={this.state.parfumlist}
                                                      parfumOnSliders={this.state.parfumOnSliders} 
                                                      setParfumOnSlider={this.setParfumOnSlider}

                                                      // slidertarget={this.slidertarget}
                                                      />}  />  
                 
                  <Route path='/q1' render={()=> <Quizarea 
                                                      pageIndex={0} 
                                                      sex={this.state.sex}
                                                      answeraction={this.setAnswer} 
                                                      quizlist={this.state.quizlist} 
                                                      quizanswer={this.state.quizanswer} 
                                                      loggedIn={this.state.loggedIn} 
                                                      jsonReady={this.state.jsonpreload} 
                                                       />}  />
                  <Route path='/q2' render={()=> <Quizarea 
                                                      pageIndex={1} 
                                                      sex={this.state.sex}
                                                      answeraction={this.setAnswer}
                                                      quizlist={this.state.quizlist} 
                                                      quizanswer={this.state.quizanswer} 
                                                      loggedIn={this.state.loggedIn} 
                                                      jsonReady={this.state.jsonpreload} 
                                                       />}  />

                  <Route path='/q3' render={()=> <Quizarea 
                                                      pageIndex={2} 
                                                      sex={this.state.sex}
                                                      answeraction={this.setAnswer}
                                                      quizlist={this.state.quizlist} 
                                                      quizanswer={this.state.quizanswer} 
                                                      loggedIn={this.state.loggedIn} 
                                                      jsonReady={this.state.jsonpreload} 
                                                      />}  />
                  <Route path='/q4' render={()=> <Quizarea 
                                                      pageIndex={3} 
                                                      sex={this.state.sex}
                                                      answeraction={this.setAnswer}
                                                      quizlist={this.state.quizlist}
                                                      quizanswer={this.state.quizanswer}  
                                                      loggedIn={this.state.loggedIn} 
                                                      jsonReady={this.state.jsonpreload} 
                                                      />}  />

                  <Route path='/q5' render={()=> <Quizarea 
                                                      pageIndex={4} 
                                                      sex={this.state.sex}
                                                      answeraction={this.setAnswer}
                                                      quizlist={this.state.quizlist} 
                                                      quizanswer={this.state.quizanswer} 
                                                      loggedIn={this.state.loggedIn} 
                                                      jsonReady={this.state.jsonpreload} 
                                                      />}  />

                  <Route path='/q6' render={()=> <Quizarea 
                                                      pageIndex={5} 
                                                      sex={this.state.sex}
                                                      answeraction={this.setAnswer} 
                                                      quizlist={this.state.quizlist} 
                                                      quizanswer={this.state.quizanswer} 
                                                      loggedIn={this.state.loggedIn} 
                                                      jsonReady={this.state.jsonpreload} 
                                                      />}  />   

                  <Route path='/q7' render={()=> <Quizarea 
                                                      pageIndex={6} 
                                                      sex={this.state.sex}
                                                      answeraction={this.setAnswer}
                                                      quizlist={this.state.quizlist} 
                                                      quizanswer={this.state.quizanswer} 
                                                      loggedIn={this.state.loggedIn} 
                                                      jsonReady={this.state.jsonpreload} 
                                                      />}  />    

                  <Route path='/q8' render={()=> <Quizarea 
                                                      pageIndex={7} 
                                                      sex={this.state.sex}
                                                      answeraction={this.setAnswer}
                                                      quizlist={this.state.quizlist} 
                                                      quizanswer={this.state.quizanswer} 
                                                      loggedIn={this.state.loggedIn} 
                                                      jsonReady={this.state.jsonpreload} 
                                                      />}  />  

                  

                  <Route path='/thankyou' render={()=> <Thankyou
                                                      forceIntro={this.forceIntro} 
                                                      loggedIn={this.state.loggedIn} 
                                                      dob={this.state.dob}  
                                                      sex={this.state.sex}
                                                      staffID={this.state.staffID}
                                                      quizlist={this.state.quizlist} 
                                                      quizanswer={this.state.quizanswer} 
                                                      jsonReady={this.state.jsonpreload} 
                                                      // parfumOnSliders={this.state.parfumOnSliders}
                                                      sendAction={this.state.sendAction}
                                                      registerAction={this.registerSurveyData} 
                                                      updateHash={this.updateHash}  
                                                      dataSent={this.state.dataSent}
                                                      />}  />

                  <Route path='/surveylist' render={()=> <Surveylist {...this.props}
                                                      jsonReady={this.state.jsonpreload} 
                                                      loggedIn={this.state.loggedIn} 
                                                      staffID={this.state.staffID}  
                                                      onUpdate={this.updateSurveyList} 
                                                      />}  /> 
                  <Route path='/survey/edit/:id' render={(props) => <Surveywrap {...props}
                                                                     surveyList={this.state.surveyList}
                                                      />}  /> 

                  
                  
                </Switch>
              </Transition>
            </TransitionGroup>
          </div>
        </ParallaxProvider>
        <Quizpager {...this.props}
                    scenes={this.state.scene} 
                    nextScene={this.nextScene} 
                    prevScene={this.prevScene} 
                    />
        <Soundtrigger
          hoverplay={this.state.hoverplay1} 
          soundevent={this.state.soundevent1} 
          onPlaySound={this.onPlaySound1} 
          onSoundFinishedPlaying={this.onSoundFinishedPlaying1} 
        />
        <Soundtrigger2
          hoverplay={this.state.hoverplay2} 
          soundevent={this.state.soundevent2} 
          onPlaySound={this.onPlaySound2} 
          onSoundFinishedPlaying={this.onSoundFinishedPlaying2} 
        />
      </div>
    );

  }
};
export default withRouter(App);